import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Loader } from "../../atoms";
/**
 * Authenticate is a component that renders the authentication page.
 */
export const Authenticate = ({ className, isEasterEgg }) => {
    return (_jsx(Wrapper, { children: _jsx(Loader, { className: className, isEasterEgg: isEasterEgg }) }));
};
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
Authenticate.displayName = 'Authenticate';
export default Authenticate;
