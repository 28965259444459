import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
// components
import { Accordion, Button, Title } from "../../atoms";
import { Editor, validationStringToMethod, } from "../Editor/Editor";
/**
 * RecipientProfileAbout shows the profile of the care recipient.
 */
export const RecipientProfileAbout = ({ className, name, fallback, keyInformation, nextOfKin, professionalContacts, answers, setAnswers, onSave, isEditing, isModify = false, }) => {
    const getFields = useCallback((questions) => {
        let fields = {};
        const assignField = (question) => {
            var _a, _b;
            let table = {};
            // @ts-ignore
            if (question.fieldTable) {
                // @ts-ignore
                const splitTable = (_a = question.fieldTable) === null || _a === void 0 ? void 0 : _a.split(';');
                const title = splitTable[0];
                const columns = splitTable[1].split(',');
                const rows = splitTable[2].split(',');
                table = {
                    title: title,
                    columns: columns,
                    rows: rows,
                };
            }
            fields[question.id] = {
                label: question.label.replace('{careRecipient}', name || 'Care Recipient'),
                isRequired: question.isRequired,
                placeholder: question.placeholder,
                tooltip: question.tooltip,
                order: question.order,
                validation: validationStringToMethod(question.validation),
                field: {
                    type: question.fieldType,
                    options: (_b = question.fieldOptions) === null || _b === void 0 ? void 0 : _b.map((option) => ({
                        value: option.id,
                        label: option.label,
                        order: option.order,
                    })).sort((a, b) => a.label.localeCompare(b.label)),
                    table: table,
                },
            };
        };
        questions === null || questions === void 0 ? void 0 : questions.forEach((question) => {
            assignField(question);
            const answer = answers === null || answers === void 0 ? void 0 : answers[question.id];
            const createSubQuestions = (question, answer) => {
                var _a;
                if (answer !== undefined) {
                    if (answer === true)
                        answer = 'true';
                    if (answer === false)
                        answer = 'false';
                    (_a = question.careProfileSubQuestions) === null || _a === void 0 ? void 0 : _a.forEach((subQuestion) => {
                        let conditionValue = subQuestion.conditionValue;
                        switch (subQuestion.conditionOperator) {
                            case 'equal':
                                if (answer === conditionValue)
                                    break;
                                return;
                            case 'not-equal':
                                if (answer !== conditionValue)
                                    break;
                                return;
                            case 'contains':
                                if (answer.includes(conditionValue)) {
                                    break;
                                }
                                return;
                            case 'not-contains':
                                if (answer.includes(conditionValue)) {
                                    break;
                                }
                                return;
                            case 'greater-than':
                                if (conditionValue === undefined)
                                    break;
                                if (answer > conditionValue)
                                    break;
                                return;
                            case 'less-than':
                                if (conditionValue === undefined)
                                    break;
                                if (answer < conditionValue)
                                    break;
                                return;
                            case 'greater-than-or-equal':
                                if (conditionValue === undefined)
                                    break;
                                if (answer >= conditionValue)
                                    break;
                                return;
                            case 'less-than-or-equal':
                                if (conditionValue === undefined)
                                    break;
                                if (answer <= conditionValue)
                                    break;
                                return;
                        }
                        assignField(subQuestion);
                        const subQuestionAnswer = answers === null || answers === void 0 ? void 0 : answers[subQuestion.id];
                        if (subQuestionAnswer !== undefined) {
                            createSubQuestions(subQuestion, subQuestionAnswer);
                        }
                    });
                }
            };
            createSubQuestions(question, answer);
        });
        return {
            id: 'key-information',
            title: 'Key Information',
            isComplete: questions.every((question) => ((answers === null || answers === void 0 ? void 0 : answers[question.id]) !== undefined ||
                question.isRequired === false) &&
                (answers === null || answers === void 0 ? void 0 : answers[question.id]) !== ''),
            fields: fields,
        };
    }, [answers, fallback, name]);
    // memos
    const keyInformationForm = useMemo(() => keyInformation && getFields(keyInformation), [getFields, keyInformation]);
    const nextOfKinForm = useMemo(() => nextOfKin && getFields(nextOfKin), [getFields, nextOfKin]);
    const professionalContactsForm = useMemo(() => professionalContacts && getFields(professionalContacts), [getFields, professionalContacts]);
    const SaveButton = useMemo(() => {
        if (!isModify) {
            return null;
        }
        return (_jsx(StyledButton, Object.assign({ variant: isEditing ? 'primary' : 'secondary', onPress: onSave, ariaLabel: isEditing ? 'save' : 'edit' }, { children: isEditing ? 'Save' : 'Edit' })));
    }, [isEditing, onSave, isModify]);
    return (_jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsx(BackButton, { children: _jsx(Button, Object.assign({ ariaLabel: 'Back', variant: 'secondary', iconLeft: 'chevron-left', onPress: () => window.history.back() }, { children: "Back" })) }), _jsx(Title, { children: "Key Information" }), _jsxs(SupportText, { children: [`${name}'s`, " key information for you to share with others in the support network when needed."] }), _jsx(Accordion, { items: [
                    {
                        id: 'key-information',
                        label: 'Key Information',
                        content: keyInformationForm && (_jsxs(_Fragment, { children: [SaveButton, _jsx(Editor, { fields: keyInformationForm.fields, isActionsDisabled: true, isFloatingActions: false, value: answers, isDisabled: !isEditing, onChange: (val, key) => setAnswers === null || setAnswers === void 0 ? void 0 : setAnswers(Object.assign(Object.assign({}, answers), { [key]: val[key] })) })] })),
                    },
                    {
                        id: 'next-of-kin',
                        label: 'Next of Kin',
                        content: nextOfKinForm && (_jsxs(_Fragment, { children: [SaveButton, _jsx(Editor, { fields: nextOfKinForm.fields, isActionsDisabled: true, isFloatingActions: false, value: answers, isDisabled: !isEditing, onChange: (val, key) => setAnswers === null || setAnswers === void 0 ? void 0 : setAnswers(Object.assign(Object.assign({}, answers), { [key]: val[key] })) })] })),
                    },
                    {
                        id: 'professional-contacts',
                        label: 'Professional Contacts',
                        content: professionalContactsForm && (_jsxs(_Fragment, { children: [SaveButton, _jsx(Editor, { fields: professionalContactsForm.fields, isActionsDisabled: true, isFloatingActions: false, value: answers, isDisabled: !isEditing, onChange: (val, key) => setAnswers === null || setAnswers === void 0 ? void 0 : setAnswers(Object.assign(Object.assign({}, answers), { [key]: val[key] })) })] })),
                    },
                ] })] })));
};
const Wrapper = styled.div `
  padding-bottom: ${(props) => props.theme.spacing[24]};
`;
const SupportText = styled.div `
  color: ${(props) => props.theme.color.neutral[80]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const StyledButton = styled(Button) `
  margin-top: ${(props) => props.theme.spacing[24]};
  margin-left: auto;
`;
const BackButton = styled.div `
  display: block;
  margin-bottom: ${(props) => props.theme.spacing[20]};
`;
RecipientProfileAbout.displayName = 'RecipientProfileAbout';
export default RecipientProfileAbout;
