import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Container, Title } from "../../atoms";
import { Editor } from "../../organisms";
import { Content } from "../../templates";
/**
 * EditorPage is an editor for creating a new care profile.
 */
export const EditorPage = ({ fields, value, onChange, title, description, onSave, onCancel, saveLabel = 'Save', cancelLabel = 'Cancel', isSaveDisabled, start, }) => {
    return (_jsx(Content, { content: _jsx(Container, { children: _jsxs(Wrapper, { children: [_jsxs(Header, { children: [_jsx(Title, { children: title }), description && _jsx(Description, { children: description })] }), start, _jsx(Editor, { fields: fields, value: value, onChange: onChange, saveLabel: saveLabel, cancelLabel: cancelLabel, onSave: onSave, onCancel: onCancel, isSaveDisabled: isSaveDisabled, isWithArrows: true })] }) }) }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  width: 100%;
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
EditorPage.displayName = 'EditorPage';
export default EditorPage;
