// react
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import styled, { createGlobalStyle, css } from 'styled-components';
import { createPortal } from 'react-dom';

// store
import { dispatch, useSelector } from 'store';
import { getAccount, getIsAuthenticated } from 'store/slices/iamSlice';
import { getCurrentCareCircleId } from 'store/slices/careCircleSlice';
import {
  useGetSessionCareCircleMemberQuery,
  useGetSessionCareCircleQuery,
  useLazyGetByAccountQuery,
} from 'store/api/careCircle';
import {
  getToastQueue,
  removeToast,
  ToastQueueItem,
} from 'store/slices/toastSlice';

// routes
import { createRouter } from 'router';

// components
import {
  AppUpdateDialog,
  OfflineOverlay,
  ThemeProvider,
  Toast,
  Version,
} from '@karehero/llama';

// hooks
import {
  useApp,
  useAppUpdate,
  useKinde,
  useMixpanel,
  useOnlineStatus,
  useWindowSize,
  useDeepLink,
} from 'hooks';

// launch darkly
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { createLdContext } from 'launchDarkly';

/**
 * App is the main component of the application.
 */
const App = () => {
  // state
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [currentToast, setCurrentToast] = useState<ToastQueueItem | null>(null);

  // hooks
  const { deepLink } = useDeepLink();
  const ldClient = useLDClient();
  const windowSize = useWindowSize();
  const flags = useFlags();
  const account = useSelector(getAccount);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const toastQueue = useSelector(getToastQueue);
  const currentCareCircleId = useSelector(getCurrentCareCircleId);
  const isOnline = useOnlineStatus();
  const [getByAccount] = useLazyGetByAccountQuery();
  const { isApp, isApple } = useApp();
  const { signOut } = useKinde();
  const { isDialogVisible, setIsDialogVisible, updateApp } = useAppUpdate();
  useMixpanel();
  useGetSessionCareCircleQuery(undefined, {
    skip: !account || account.accountRole.name !== 'Member',
  });
  useGetSessionCareCircleMemberQuery(undefined, {
    skip: !account || account.accountRole.name !== 'Member',
  });

  // effects
  useEffect(() => {
    setIsToastOpen(toastQueue.length > 0);
    setTimeout(() => {
      setCurrentToast(toastQueue[0]);
      setIsToastOpen(true);
    }, 200);
  }, [toastQueue]);

  useEffect(() => {
    if (!isToastOpen) {
      dispatch(removeToast());
    }
  }, [isToastOpen]);

  useEffect(() => {
    if (account && account.accountRole.name !== 'Organization Owner') {
      getByAccount();
    }
  }, [getByAccount, account]);

  useEffect(() => {
    if (!!account) {
      const newContext = createLdContext(account);
      ldClient?.identify(newContext);
    }
  }, [account, ldClient]);

  // global styles
  const Global = createGlobalStyle`
    #hubspot-messages-iframe-container {
      opacity: 0;
      pointer-events: none;
      touch-action: none;
    }
  `;

  return (
    <ThemeProvider>
      <Global />
      {currentToast && (
        <Toast
          title={currentToast?.title || ''}
          description={currentToast?.description || ''}
          variant={currentToast?.variant || 'plain'}
          isOpen={isToastOpen}
          setIsOpen={setIsToastOpen}
          isApple={isApp && isApple}
        />
      )}
      <AppWrapper height={windowSize[1]} $isGestureBar={isApp && isApple}>
        {/*
          This is a temporary solution to display the deep link & debug.
          This will be removed once the deep link is implemented &  confirmed.
        */}
        {flags.deepLink && <div>Deep link: {deepLink}</div>}
        {flags.deepLink && (
          <div>
            <a href={deepLink ? deepLink : 'https://www.google.com'}>
              {deepLink ? 'Register link' : 'Google'}
            </a>
          </div>
        )}
        <Version
          nodeEnvironment={process.env.NODE_ENV}
          buildEnvironment={process.env.REACT_APP_BUILD_ENVIRONMENT}
          buildVersion={process.env.REACT_APP_BUILD_VERSION}
          buildHash={process.env.REACT_APP_BUILD_HASH}
          onSignOut={signOut}
        />
        {!isOnline &&
          createPortal(
            <OfflineOverlay />,
            document.getElementById('offline-overlay')!,
          )}
        <RouterWrapper>
          <AppUpdateDialog
            isDialogVisible={isDialogVisible}
            setIsDialogVisible={setIsDialogVisible}
            onUpdate={updateApp}
          />
          <RouterProvider
            router={createRouter({
              isAuthenticated,
              isOnboarded: account?.isOnboarded,
              account,
              currentCareCircleId,
              isCareCircle: flags.careCircle,
              isAttendanceAllowance: flags.attendanceAllowance,
            })}
          />
        </RouterWrapper>
      </AppWrapper>
    </ThemeProvider>
  );
};

interface AppWrapperProps {
  height: number;
  $isGestureBar?: boolean;
}
const AppWrapper = styled.div<AppWrapperProps>`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height}px;
  background: ${(props) => props.theme.color.neutral[10]};
  position: relative;
  z-index: 0;
  ${(props) =>
    props.$isGestureBar &&
    css`
      padding-bottom: 21px;
    `}
`;

const RouterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export default App;
