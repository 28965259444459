import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
// components
import Chip from "../MultiSelect/Chip";
import { BaseField, Button, Input, } from "..";
/**
 * MultiSelectChip allows the user to select multiple values from a list of
 * options in the form of chips.
 */
export const MultiSelectChip = ({ className, id, label, description, tooltip, isRequired, isHideTitle, options, value, onChange, isDisabled = false, isCustom = true, }) => {
    // state
    const [customValue, setCustomValue] = useState('');
    const [isAddAnother, setIsAddAnother] = useState(false);
    // memo
    const valueArray = useMemo(() => JSON.parse(value || '[]'), [value]);
    const customValues = useMemo(() => {
        return valueArray.filter((value) => !options.find((option) => option.value === value));
    }, [options, valueArray]);
    // methods
    const handleChipPress = useCallback((value) => {
        const index = valueArray.indexOf(value);
        if (index === -1) {
            onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify([...valueArray, value]));
        }
        else {
            const newValue = [...valueArray];
            newValue.splice(index, 1);
            onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify(newValue));
        }
    }, [valueArray, onChange]);
    const handleSaveCustomValue = useCallback(() => {
        // check if value already exists in options
        const option = options.find((option) => option.label === customValue);
        if (option) {
            handleChipPress(option.value);
        }
        else {
            if (customValue.length !== 0 && !valueArray.includes(customValue)) {
                onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify([...valueArray, customValue]));
            }
        }
        setIsAddAnother(false);
        setCustomValue('');
    }, [valueArray, customValue, onChange, setIsAddAnother, setCustomValue]);
    return (_jsx(BaseField, Object.assign({ id: id, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsxs(Wrapper, { children: [_jsxs(ChipGroup, Object.assign({ className: className }, { children: [options.map((option) => (_jsx(Chip, { label: option.label, value: option.value, onPress: handleChipPress, isDisabled: isDisabled, isSelected: valueArray.includes(option.value) }, option.value))), customValues.length > 0 &&
                            customValues.map((customValue) => (_jsx(Chip, { label: customValue, value: customValue, onPress: handleChipPress, isDisabled: isDisabled, isSelected: true }, customValue)))] })), isCustom && (_jsxs(AddAnotherWrapper, { children: [isAddAnother && (_jsx(Input, { ariaLabel: 'add another', placeholder: 'Add custom', value: customValue, onChange: (val) => setCustomValue(val || ''), isDisabled: isDisabled, autoFocus: true })), _jsxs(ActionWrapper, { children: [!isAddAnother && (_jsx(Button, Object.assign({ ariaLabel: 'add another', variant: 'secondary', size: 'md', iconLeft: 'plus', onPress: () => setIsAddAnother(true), isDisabled: isDisabled }, { children: "Add another" }))), isAddAnother && (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ ariaLabel: 'save', variant: 'secondary', size: 'sm', type: 'submit', onPress: handleSaveCustomValue, isDisabled: isDisabled }, { children: "Save" })), _jsx(Button, Object.assign({ ariaLabel: 'cancel', variant: 'link', size: 'sm', onPress: () => {
                                                setIsAddAnother(false);
                                                setCustomValue('');
                                            } }, { children: "Cancel" }))] }))] })] }))] }) })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[48]};
`;
const ChipGroup = styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing[8]};
`;
const AddAnotherWrapper = styled.form `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[16]};
`;
const ActionWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
`;
MultiSelectChip.displayName = 'MultiSelectChip';
export default MultiSelectChip;
