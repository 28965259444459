import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// radix
import * as ToggleGroup from '@radix-ui/react-toggle-group';
// components
import { Icon } from "../Icon";
export const ToggleSelect = ({ options, value, defaultValue, onOptionSelected, }) => {
    return (_jsx(StyledToggleButtonGroup, Object.assign({ type: 'single', value: value, defaultValue: defaultValue || options[0].value, onValueChange: onOptionSelected }, { children: options === null || options === void 0 ? void 0 : options.map((option) => (_jsxs(StyledToggleButton, Object.assign({ value: option.value }, { children: [option.icon && (_jsx(IconWrapper, { children: _jsx(Icon, { fontSize: 16, type: 'fal', icon: option.icon }) })), option.label] }), option.value))) })));
};
const StyledToggleButtonGroup = styled(ToggleGroup.Root) `
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => props.theme.color.neutral[50]};
  border-radius: 4px;
  overflow: hidden;
`;
const StyledToggleButton = styled(ToggleGroup.Item) `
  padding: ${(props) => props.theme.spacing[8]};
  flex-grow: 1;
  user-select: none;
  cursor: pointer;
  border: none;
  color: black;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-state='on'] {
    background-color: ${(props) => props.theme.color.neutral[30]};
  }

  &:focus {
    outline: none;
    background-color: ${(props) => props.theme.color.neutral[30]};
  }
`;
const IconWrapper = styled.div `
  all: unset;
  display: flex;
  padding-right: ${(props) => props.theme.spacing[8]};
`;
ToggleSelect.displayName = 'ToggleSelect';
export default ToggleSelect;
