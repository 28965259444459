var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
// components
import { Avatar, Button, Checkbox, Dialog, Label, } from "../../atoms";
import { CareCircleInvite } from "../../organisms";
import { CardContainer } from "../../templates";
import { BaseField } from "../../atoms/BaseField/BaseField";
/**
 * CareCircleMemberSelector allows the user to select multiple values from a list of
 * careCircle in the form of checkboxes.
 */
export const CareCircleMemberSelector = ({ id, className, careCircle, maxColumns, isDisabled = false, relationshipOptions, onInvite, label, description, tooltip, isRequired = false, isHideTitle = false, onChange, value, }) => {
    // state
    const [isInviteOpen, setIsInviteOpen] = useState(false);
    const [valueArray, setValueArray] = useState([]);
    // effects
    useEffect(() => {
        try {
            setValueArray(JSON.parse(value || '[]'));
        }
        catch (e) {
            console.error('Failed to parse value:', value, e);
            setValueArray([]);
        }
    }, [value]);
    // methods
    const handleCheckboxChange = useCallback((isChecked, optionValue) => {
        setValueArray((prevValueArray) => {
            const updatedValueArray = isChecked
                ? [...prevValueArray, optionValue]
                : prevValueArray.filter((v) => v !== optionValue);
            onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify(updatedValueArray));
            return updatedValueArray;
        });
    }, [onChange]);
    const handleInvite = useCallback((careCircleMemberInvite) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const res = yield (onInvite === null || onInvite === void 0 ? void 0 : onInvite(careCircleMemberInvite));
            if (!res || 'error' in res)
                return;
            setIsInviteOpen(false);
        }
        catch (e) {
            console.error(e);
        }
    }), [onInvite]);
    return (_jsxs(BaseField, Object.assign({ id: id, className: className, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: [_jsx(Wrapper, { children: _jsx(CardContainer, Object.assign({ maxColumns: maxColumns !== null && maxColumns !== void 0 ? maxColumns : 1, size: 'sm' }, { children: careCircle.careCircleMembers.map((member) => (_jsxs(MemberRow, { children: [_jsxs(MemberInfo, { children: [_jsx(Avatar, { fallback: member.account.firstName.charAt(0), size: 'sm' }), _jsxs(MemberDetails, { children: [_jsx(RoleLabel, { id: member.label, text: member.account.accountRole.name }), _jsx(Label, { id: member.label, text: `${member.account.firstName || ''} ${member.account.lastName || ''}` })] })] }), _jsx(Checkbox, { id: `${id}-${member.value}`, size: 'sm', value: !!valueArray.includes(member.value), onChange: (val) => handleCheckboxChange(val, member.value), isDisabled: isDisabled })] }, member.value))) })) }), _jsx(InviteButtonWrapper, { children: _jsx(Button, Object.assign({ ariaLabel: 'invite new member', variant: 'secondary', iconLeft: 'plus', onPress: () => setIsInviteOpen(true), isDisabled: isDisabled }, { children: "Invite new member" })) }), _jsx(Dialog, Object.assign({ isOpen: isInviteOpen, setIsOpen: setIsInviteOpen }, { children: _jsx(CareCircleInvite, { onInvite: handleInvite, onCancel: () => setIsInviteOpen(false), relationshipOptions: relationshipOptions }) }))] })));
};
const MemberRow = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: ${(props) => props.theme.spacing[8]};

  &:last-child {
    border-bottom: none;
  }
`;
const MemberInfo = styled.div `
  display: flex;
  align-items: center;
`;
const RoleLabel = styled(Label) `
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.body['sm']};
`;
const MemberDetails = styled.div `
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.theme.spacing[8]};
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: ${(props) => props.theme.spacing[8]};
  margin-bottom: ${(props) => props.theme.spacing[8]};
  max-height: 200px;
  overflow: auto;
`;
const InviteButtonWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
`;
CareCircleMemberSelector.displayName = 'CareCircleMemberSelector';
export default CareCircleMemberSelector;
