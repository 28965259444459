import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { css } from 'styled-components';
// spring-carousel
import { useSpringCarousel } from 'react-spring-carousel';
// components
import { Icon, LoadingTransition, Skeleton } from "..";
/**
 * Carousel displays a series of images or videos.
 */
export const Carousel = ({ items, title, isLoading }) => {
    const { carouselFragment, slideToPrevItem, slideToNextItem } = 
    // @ts-ignore
    useSpringCarousel({
        slideType: 'fluid',
        slideAmount: 266,
        items: items === null || items === void 0 ? void 0 : items.map((item, i) => {
            return Object.assign(Object.assign({}, item), { index: i, renderItem: (_jsx(StyledCarouselItem, { children: item.renderItem })) });
        }),
    });
    if (!items || items.length === 0)
        return null;
    return (_jsxs(StyledCarousel, Object.assign({ "$isLoading": isLoading }, { children: [_jsxs(CarouselHeader, { children: [_jsx(Left, { children: _jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Skeleton, { shape: 'title', width: '400px', height: '36px', isMargin: false }), content: _jsx(Title, { children: title }) }) }), _jsxs(Right, { children: [_jsx(StyledButton, Object.assign({ onClick: slideToPrevItem }, { children: _jsx(Icon, { icon: 'chevron-left', fontSize: 16 }) })), _jsx(StyledButton, Object.assign({ onClick: slideToNextItem }, { children: _jsx(Icon, { icon: 'chevron-right', fontSize: 16 }) }))] })] }), _jsx(StyledCarouselContainer, { children: carouselFragment })] })));
};
const StyledCarousel = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: stretch;
  gap: ${(props) => props.theme.spacing[12]};

  & *::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${(props) => props.$isLoading &&
    css `
      pointer-events: none;
    `}
`;
const CarouselHeader = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.body.xl.medium};
`;
const Left = styled.div ``;
const Right = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
`;
const StyledCarouselContainer = styled.div `
  display: flex;
  align-items: center;
  margin: -${(props) => props.theme.spacing[8]};
`;
const StyledCarouselItem = styled.div `
  padding: ${(props) => props.theme.spacing[8]};
  padding-top: ${(props) => props.theme.spacing[12]};
  padding-bottom: ${(props) => props.theme.spacing[12]};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledButton = styled.button `
  all: unset;
  color: ${(props) => props.theme.color.neutral[80]};
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
  border-radius: 99999px;
  border: 1px solid ${(props) => props.theme.color.neutral[80]};

  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.1);
    color: ${(props) => props.theme.color.neutral[90]};
  }
`;
Carousel.displayName = 'Carousel';
export default Carousel;
