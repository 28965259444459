import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
// components
import { Container, Icon, ProgressBar } from "../../atoms";
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
const animationDuration = 1000;
export const OnboardingLoading = toGenericOnboarding(({ config, currentIndex = 0, isComplete, }) => {
    // state
    const [isVisible, setIsVisible] = useState(false);
    const [currentLoadingIndex, setCurrentLoadingIndex] = useState(currentIndex);
    // memos
    const currentLoadingConfig = useMemo(() => config[currentLoadingIndex], [config, currentLoadingIndex]);
    const currentConfig = useMemo(() => config[currentIndex], [config, currentIndex]);
    // effects
    useEffect(() => {
        setIsVisible(false);
        const timer = setTimeout(() => {
            setCurrentLoadingIndex(currentIndex);
            setIsVisible(true);
        }, animationDuration / 2);
        return () => clearInterval(timer);
    }, [currentIndex]);
    // guard clause
    if (!currentLoadingConfig)
        return _jsx(_Fragment, {});
    if (!currentConfig)
        return _jsx(_Fragment, {});
    return (_jsx(StyledContainer, Object.assign({ size: 'sm', color: currentConfig.color }, { children: _jsxs(Wrapper, { children: [_jsxs(ContentWrapper, Object.assign({ "$isVisible": isVisible }, { children: [(currentLoadingConfig === null || currentLoadingConfig === void 0 ? void 0 : currentLoadingConfig.icon) && (_jsx(ImageWrapper, Object.assign({ "$bgColor": currentLoadingConfig.color }, { children: _jsx(Icon, { fontSize: 40, icon: currentLoadingConfig.icon }) }))), _jsx(Text, { children: currentLoadingConfig.text })] })), _jsx(ProgressBar, { label: ' ', limit: config.length - 1, progress: currentIndex })] }) })));
});
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 172px;
  gap: ${(props) => props.theme.spacing[32]};
  ${(props) => !props.$isVisible &&
    css `
      opacity: 0;
      transform: scale(0.8);
    `}
  transition-duration: ${animationDuration / 2}ms;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 ${(props) => props.theme.spacing[24]};
`;
const StyledContainer = styled(Container) `
  background: ${(props) => { var _a; return (_a = props.theme.color.cards[props.color]) === null || _a === void 0 ? void 0 : _a.primary; }};
  transition-duration: ${animationDuration}ms;
  height: unset;
  flex: 1;
`;
const ImageWrapper = styled.div `
  width: 72px;
  height: 72px;
  min-width: 72px;
  min-height: 72px;
  max-width: 72px;
  max-height: 72px;
  border-radius: 999999px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.color.neutral[90]};
  background: ${(props) => { var _a; return (_a = props.theme.color.cards[props.$bgColor]) === null || _a === void 0 ? void 0 : _a.secondary; }};
  padding: ${(props) => props.theme.spacing[12]};
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: center;
`;
OnboardingLoading.displayName = 'OnboardingLoading';
export default toGenericOnboarding(OnboardingLoading);
