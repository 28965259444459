// react
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'store';

// service workers
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import reportWebVitals from 'reportWebVitals';

// styles
import 'index.css';

// components
import App from 'App';

// launchdarkly
import { getLdProvider } from 'launchDarkly';

// hotjar
import Hotjar from '@hotjar/browser';

const siteId = process.env.NODE_ENV === 'production' ? 3612117 : 3535435;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

(async () => {
  const LDProvider = await getLdProvider();

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <LDProvider>
          <App />
        </LDProvider>
      </Provider>
    </React.StrictMode>,
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
