import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
// radix
import * as RadixProgress from '@radix-ui/react-progress';
// theme
import { useTheme } from 'styled-components';
// images
// @transform-path ../../../../../../src/assets/progress-plant/plant-1.svg
import image1 from "../../../../../../src/assets/progress-plant/plant-1.svg";
// @transform-path ../../../../../../src/assets/progress-plant/plant-2.svg
import image2 from "../../../../../../src/assets/progress-plant/plant-2.svg";
// @transform-path ../../../../../../src/assets/progress-plant/plant-3.svg
import image3 from "../../../../../../src/assets/progress-plant/plant-3.svg";
// @transform-path ../../../../../../src/assets/progress-plant/plant-4.svg
import image4 from "../../../../../../src/assets/progress-plant/plant-4.svg";
// @transform-path ../../../../../../src/assets/progress-plant/plant-5.svg
import image5 from "../../../../../../src/assets/progress-plant/plant-5.svg";
// @transform-path ../../../../../../src/assets/progress-plant/plant-6.svg
import image6 from "../../../../../../src/assets/progress-plant/plant-6.svg";
/**
 * ProgressBar shows the progress of a task.
 */
export const ProgressBar = ({ className, label, limit, progress = 0, color, isPlantIconVisible, isDarkMode, isAnimate = true, }) => {
    // hooks
    const theme = useTheme();
    // memos
    const getImageForProgress = useMemo(() => {
        if (progress >= 0 && progress < limit * 0.2) {
            return image1;
        }
        else if (progress >= limit * 0.2 && progress < limit * 0.4) {
            return image2;
        }
        else if (progress >= limit * 0.4 && progress < limit * 0.6) {
            return image3;
        }
        else if (progress >= limit * 0.6 && progress < limit * 0.8) {
            return image4;
        }
        else if (progress >= limit * 0.8 && progress < limit) {
            return image5;
        }
        else {
            return image6;
        }
    }, [progress, limit]);
    const progressLimited = useMemo(() => {
        return Math.max(0, Math.min(progress, limit));
    }, [progress, limit]);
    return (_jsxs(Wrapper, Object.assign({ className: className }, { children: [label && (_jsxs(LabelContainer, { children: [_jsx(StyledLabel, Object.assign({ "$isDarkMode": isDarkMode }, { children: label })), _jsxs(StyledLabel, Object.assign({ "$isDarkMode": isDarkMode }, { children: [Math.round((progressLimited / limit) * 100), "%"] }))] })), _jsxs(ProgressWrapper, { children: [_jsx(ProgressRoot, Object.assign({ value: progressLimited, color: color ? theme.color.cards[color].secondary : theme.color.neutral[40] }, { children: _jsx(ProgressIndicator, { limit: limit, progress: progressLimited, "$isAnimate": isAnimate }) })), isPlantIconVisible && (_jsx(StyledImg, { src: getImageForProgress, alt: 'Plant Icon' }))] })] })));
};
const Wrapper = styled.div `
  width: 100%;
`;
const ProgressRoot = styled(RadixProgress.Root) `
  position: relative;
  overflow: hidden;
  background: ${(props) => props.color};
  border-radius: 9999px;
  width: 100%;
  height: 8px;

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
`;
const ProgressIndicator = styled(RadixProgress.Indicator) `
  background: ${(props) => props.theme.color.success[40]};
  width: 100%;
  height: 100%;
  ${(props) => props.$isAnimate &&
    css `
      transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
    `}
  transform: translateX(
    -${(props) => 100 - (props.progress / props.limit) * 100}%
  );
`;
const LabelContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing[8]};
`;
const Label = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
`;
const StyledLabel = styled(Label) `
  color: ${(props) => props.$isDarkMode
    ? props.theme.color.neutral[10]
    : props.theme.color.neutral[80]};
`;
const ProgressWrapper = styled.div `
  display: flex;
  align-items: center;
`;
const StyledImg = styled.img `
  margin-left: 8px;
  height: 30px;
  width: 30px;
`;
ProgressBar.displayName = 'ProgressBar';
export default ProgressBar;
