var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
// components
import { Button, Dialog, Tabs, Title } from "../../atoms";
// organisms
import { ChangePasswordForm } from "../../organisms";
/**
 * Settings renders the account settings page.
 */
export const Settings = ({ account, onConfirm, appVersion, apiVersion, }) => {
    // state
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
    const [searchParams] = useSearchParams();
    // methods
    const handleConfirm = useCallback((password, confirmPassword) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield (onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(password, confirmPassword));
            setIsChangePasswordOpen(false);
        }
        catch (e) {
            console.error(e);
        }
    }), [onConfirm]);
    return (_jsxs(Wrapper, { children: [_jsx(Title, { children: "Settings" }), _jsx(Tabs, { defaultValue: searchParams.get('tab') || '1', tabs: [
                    {
                        id: '1',
                        label: `My account`,
                        content: (_jsxs(AccountWrapper, { children: [_jsxs(Field, { children: [_jsx(SubTitle, { children: "Name" }), _jsx(HelperText, { children: `${account.firstName} ${(account === null || account === void 0 ? void 0 : account.lastName) || ''}` })] }), _jsxs(Field, { children: [_jsx(SubTitle, { children: "Email address" }), _jsx(HelperText, { children: account.email })] }), onConfirm && (_jsxs(Field, { children: [_jsx(SubTitle, { children: "Password" }), _jsx(Button, Object.assign({ ariaLabel: 'change password', variant: 'secondary', onPress: () => setIsChangePasswordOpen(true) }, { children: "Change password" })), _jsx(Dialog, Object.assign({ title: 'Change Password', isCloseButton: true, isOpen: isChangePasswordOpen, setIsOpen: setIsChangePasswordOpen }, { children: _jsx(ChangePasswordForm, { onConfirm: handleConfirm }) }))] })), _jsxs(Field, { children: [_jsx(SubTitle, { children: "Delete account" }), _jsxs(HelperText, { children: ["If you wish to delete your account, please contact \u00A0", _jsx(Button, Object.assign({ variant: 'link', size: 'md', isInline: true, ariaLabel: 'here', to: `mailto://support@karehero.com` }, { children: "support@karehero.com" }))] })] })] })),
                    },
                    {
                        id: '2',
                        label: 'App info',
                        content: (_jsxs(AccountWrapper, { children: [_jsxs(Field, { children: [_jsx(SubTitle, { children: "App version" }), _jsx(HelperText, { children: appVersion })] }), _jsxs(Field, { children: [_jsx(SubTitle, { children: "API version" }), _jsx(HelperText, { children: apiVersion })] })] })),
                    },
                ] })] }));
};
// styles
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${(props) => props.theme.spacing[16]};
`;
const AccountWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[48]};
`;
const Field = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
  align-items: flex-start;
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const HelperText = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
`;
Settings.displayName = 'Settings';
export default Settings;
