export const color = {
    primary: {
        10: '#f8eced',
        20: '#ffced0',
        30: '#984670',
        40: '#803b5e',
        50: '#d45d6e',
        60: '#662f4b',
        70: '#b54a57',
        80: '#984670',
        90: '#8d2734',
        100: '#6e1e28',
    },
    danger: {
        10: '#ebe5e7',
        20: '#ffe8e8',
        30: '#f9c2c5',
        40: '#e7294b',
        50: '#c80131',
        60: '#af000b',
    },
    warning: {
        10: '#eae3de',
        20: '#fff3e9',
        30: '#ffdec7',
        40: '#e89a51',
        50: '#d16603',
        60: '#b15600',
    },
    success: {
        10: '#dde4e2',
        20: '#f3faf8',
        30: '#a4d7c7',
        40: '#4fa1a6',
        50: '#31766e',
        60: '#215852',
    },
    info: {
        10: '#ecf2fc',
        20: '#dce1ea',
        30: '#bfd3f8',
        40: '#3081fb',
        50: '#044dba',
        60: '#043c8f',
    },
    neutral: {
        10: '#ffffff',
        20: '#fcfdff',
        30: '#edf1f7',
        40: '#e8eaed',
        50: '#ced0d2',
        60: '#bdbdbd',
        70: '#7f8082',
        80: '#69696b',
        90: '#4d4d4d',
        100: '#000000',
    },
    cards: {
        blue: {
            primary: '#f7f7fb',
            secondary: '#eae8f8',
            tertiary: '#595475',
        },
        green: {
            primary: '#f0f6f4',
            secondary: '#d5e5de',
            tertiary: '#537565',
        },
        red: {
            primary: '#fff5f7',
            secondary: '#fde7ec',
            tertiary: '#78545c',
        },
        yellow: {
            primary: '#fcf9f5',
            secondary: '#f5ecdd',
            tertiary: '#807258',
        },
        navDrawer: {
            primary: '#edf1f7',
            secondary: '#edf1f7',
        },
    },
    actionPlanCards: {
        grey: {
            primary: '#1c1b1f',
            secondary: '#edf1f7',
        },
        green: {
            primary: '#31766e',
            secondary: '#d5e5de',
        },
        red: {
            primary: '#8d2734',
            secondary: '#fde7ec',
        },
        yellow: {
            primary: '#d16603',
            secondary: '#f5ecdd',
        },
        blue: {
            primary: '#044dba',
            secondary: '#eAe8f8',
        },
        purple: {
            primary: '#813c8d',
            secondary: '#f8defd',
        },
    },
    surfaces: {
        white: '#ffffff',
        offWhite: '#fdfdfd',
        dustySand: '#f8f3e9',
        floralWhite: '#fff8ee',
        papayaWhip: '#fff1db',
        mistyRose: '#ffe2d7',
        pastelLavender: '#e3d6ea',
        pastelThistle: '#dfc2ee',
        lightPlum: '#f8defd',
        mediumPurple: '#9e76a5',
        lavenderBlue: '#e9e8f8',
    },
    accent: {
        moccasin: '#ffe2b5',
        violetLight: '#aa5d85',
        darkPurple: '#813c8d',
    },
    illustration: {
        eggBlue: '#d6deec',
        eggBlueLight: '#e9eef6',
        steelBlue: '#b3c7ec',
        plum: '#cf9ad8',
        lightCyan: '#d9fff1',
        mediumCyan: '#b3ffe3',
        aquamarine: '#83e2c2',
        mediumAquamarine: '#65af96',
        pink: '#ffd3d5',
        mediumPink: '#ffadb0',
        peachPuff: '#ffc6b1',
    },
    custom: {
        calendarCell: '#f9f9fb',
        tableToolbar: '#f8f9fa',
        navDrawerBlue: '#e5ebf6',
        navDrawerPurple: '#883961',
        fadeUp: 'linear-gradient(180deg, #96466f 0%, #713654 96.88%)',
        fadeDown: 'linear-gradient(180deg, #713654 0%, #96466f 96.88%)',
        ai: 'linear-gradient(264deg,#ed854a -3.56%,#e14363 44.62%,#a94de1 98.09%)',
    },
    overlay: 'rgba(0, 0, 0, 0.5)',
};
export default color;
