import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { css } from 'styled-components';
/**
 * Title is the title of a page.
 */
export const Title = ({ className, children, isNoMargin = false, }) => {
    return (_jsx(StyledTitle, Object.assign({ className: className, "$isNoMargin": isNoMargin }, { children: children })));
};
const StyledTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['5xl']};
  color: ${(props) => props.theme.color.primary[80]};
  ${(props) => !props.$isNoMargin &&
    css `
      margin-bottom: ${props.theme.spacing[36]};
    `}
`;
Title.displayName = 'Title';
export default Title;
