const terms = [
    {
        _key: 'd6d9d694cbd8',
        _type: 'block',
        children: [
            {
                _key: '8c7ed1b1d07d0',
                _type: 'span',
                marks: ['strong'],
                text: 'KAREHERO APP',
            },
        ],
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '894a32e6351a',
        _type: 'block',
        children: [
            {
                _key: '86d01f5409df0',
                _type: 'span',
                marks: ['strong'],
                text: 'END USER LICENCE AGREEMENT',
            },
        ],
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '55b6cfd32d92',
        _type: 'block',
        children: [
            {
                _key: '203d9277b07b0',
                _type: 'span',
                marks: ['strong'],
                text: 'These terms and conditions were last updated on July 9 2024.',
            },
        ],
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '30494466ef73',
        _type: 'block',
        children: [
            {
                _key: 'df312d8e419b',
                _type: 'span',
                marks: [],
                text: '',
            },
        ],
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'c1ac384baccf',
        _type: 'block',
        children: [
            {
                _key: '224cdfadf1f70',
                _type: 'span',
                marks: ['strong'],
                text: 'Introduction',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'bdaaacfa3009',
        _type: 'block',
        children: [
            {
                _key: '997091acf30e0',
                _type: 'span',
                marks: ['strong'],
                text: 'PLEASE READ THIS AGREEMENT CAREFULLY.',
            },
            {
                _key: '997091acf30e1',
                _type: 'span',
                marks: [],
                text: ' This user agreement (',
            },
            {
                _key: '997091acf30e2',
                _type: 'span',
                marks: ['strong'],
                text: '“Agreement”',
            },
            {
                _key: '997091acf30e3',
                _type: 'span',
                marks: [],
                text: '):',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'b84810001027',
        _type: 'block',
        children: [
            {
                _key: '5c1b04877a950',
                _type: 'span',
                marks: [],
                text: 'applies to the KareHero App and the Services provided by KareHero (as both such terms are defined in clause 2.1) owned and provided by KareHero Limited (and its subsidiaries and affiliates), a company registered in England (company number 14314900) having its registered office at 128 City Road, London EC1V 2NX (“',
            },
            {
                _key: '5c1b04877a951',
                _type: 'span',
                marks: ['strong'],
                text: 'KareHero”',
            },
            {
                _key: '5c1b04877a952',
                _type: 'span',
                marks: [],
                text: ') (',
            },
            {
                _key: '5c1b04877a953',
                _type: 'span',
                marks: ['strong'],
                text: 'we',
            },
            {
                _key: '5c1b04877a954',
                _type: 'span',
                marks: [],
                text: ', ',
            },
            {
                _key: '5c1b04877a955',
                _type: 'span',
                marks: ['strong'],
                text: 'us',
            },
            {
                _key: '5c1b04877a956',
                _type: 'span',
                marks: [],
                text: ' and ',
            },
            {
                _key: '5c1b04877a957',
                _type: 'span',
                marks: ['strong'],
                text: 'our',
            },
            {
                _key: '5c1b04877a958',
                _type: 'span',
                marks: [],
                text: '); and',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '88faacb5b16f',
        _type: 'block',
        children: [
            {
                _key: '026259a63dce0',
                _type: 'span',
                marks: [],
                text: 'sets out the basis on which KareHero makes the KareHero App and the Services available to you to browse and/or connect/purchase Products from a Provider in accordance with this Agreement (“',
            },
            {
                _key: '026259a63dce1',
                _type: 'span',
                marks: ['strong'],
                text: 'you”',
            },
            {
                _key: '026259a63dce2',
                _type: 'span',
                marks: [],
                text: ', “',
            },
            {
                _key: '026259a63dce3',
                _type: 'span',
                marks: ['strong'],
                text: 'your',
            },
            {
                _key: '026259a63dce4',
                _type: 'span',
                marks: [],
                text: '” or “',
            },
            {
                _key: '026259a63dce5',
                _type: 'span',
                marks: ['strong'],
                text: 'User',
            },
            {
                _key: '026259a63dce6',
                _type: 'span',
                marks: [],
                text: '”) and governs the relationship between you and KareHero.\n\nThis Agreement also includes our Privacy Policy (',
            },
            {
                _key: '0306bcbd9d621',
                _type: 'span',
                marks: ['63c7a3acc5a8', 'strong'],
                text: '“Privacy Policy”',
            },
            {
                _key: '0306bcbd9d622',
                _type: 'span',
                marks: [],
                text: ').\n\nTo make payments to service Providers (see clause X), you will also agree to the service terms and the privacy policy (',
            },
            {
                _key: '078df38ad60b1',
                _type: 'span',
                marks: ['strong'],
                text: '“Payment Services Agreement”',
            },
            {
                _key: '078df38ad60b2',
                _type: 'span',
                marks: [],
                text: ') of a selected third party payment service provider (',
            },
            {
                _key: '078df38ad60b3',
                _type: 'span',
                marks: ['strong'],
                text: '“Payment Services Provider”',
            },
            {
                _key: '078df38ad60b4',
                _type: 'span',
                marks: [],
                text: ').',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [
            {
                _key: '63c7a3acc5a8',
                _type: 'link',
                href: 'https://docs.google.com/document/d/1PUJNAictrDKf2HPviMg6-xrcYYYT2yORkmc0ydZXz_o/edit#heading=h.5zqks8o4431r',
            },
        ],
        style: 'normal',
    },
    {
        _key: '6762678b578d',
        _type: 'block',
        children: [
            {
                _key: '4232e4c152f9',
                _type: 'span',
                marks: [],
                text: 'This Agreement applies to any of the KareHero Services that may be accessible to you as part of your employment benefits package, unless separate or additional terms apply, in which case they will be displayed on-screen or accessible via a link or subject to a standalone agreement between you and KareHero (as the case may be) (“',
            },
            {
                _key: '030bd1ef73751',
                _type: 'span',
                marks: ['strong'],
                text: "Additional Agreements''",
            },
            {
                _key: '030bd1ef73752',
                _type: 'span',
                marks: [],
                text: '). If there is any conflict or inconsistency between the terms of this Agreement and an Additional Agreement, the Additional Agreement shall take precedence over the terms of the Agreement to the extent of such conflict or inconsistency.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '6f5f2b9fbc2b',
        _type: 'block',
        children: [
            {
                _key: 'a06ddd6f0b2f1',
                _type: 'span',
                marks: ['strong'],
                text: 'BY CLICKING “I ACCEPT” WHERE INDICATED IN THE REGISTRATION PROCESS, YOU AGREE TO BE BOUND BY THIS AGREEMENT, INCLUDING OUR PRIVACY POLICY [AND THE PAYMENT SERVICES PROVIDER AGREEMENT] AT ALL TIMES. YOU ACKNOWLEDGE THAT THIS AGREEMENT CONSTITUTES A LEGALLY BINDING CONTRACT BETWEEN US AND YOU (AND, IF RELEVANT, ANY INDIVIDUAL ON WHOSE BEHALF YOU ARE ACTING). IF YOU ARE ACTING ON BEHALF OF ANY INDIVIDUAL, YOU REPRESENT AND WARRANT THAT YOU HAVE THE REQUISITE AUTHORITY, POWER AND RIGHT TO FULLY BIND THAT INDIVIDUAL. IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT OR YOU ARE NOT PERMITTED TO ACCESS OR USE THE KareHero APP, AND YOU MUST NOT PROCEED FURTHER.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '60ef28876419',
        _type: 'block',
        children: [
            {
                _key: '5b4fca21b043',
                _type: 'span',
                marks: [],
                text: 'To use the KareHero App, you must be a person who is at least 18 years old. You represent and warrant to us that: (a) you are at least 18 years old; (b) you have not previously been suspended, restricted or removed from any of the Services or had your access to the KareHero App revoked, suspended or restricted or been banned or removed from other e-commerce marketplace platforms; and (c) your registration, access and use of the KareHero App and the Services is in compliance with any and all Applicable Laws.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '29728cafb3c6',
        _type: 'block',
        children: [
            {
                _key: 'd52101dc96d9',
                _type: 'span',
                marks: [],
                text: 'We reserve the right, in our sole discretion, to change this Agreement from time to time to, for example, reflect changes in the market conditions affecting our business, changes in Applicable Laws, best practice, technology and payment methods, changes in our systems capabilities and to deal with additional features. When we make material changes to this Agreement, we will always give you at least ten (10) days’ notice of such changes. This may include seeking your agreement within the KareHero App by, for example, displaying the updated terms on-screen or with a link to the updated terms or by sending you an email or notifying you of the change when you next start the KareHero App. In some cases, we will notify you in advance of the changes. Please therefore make sure you read any such notice carefully. If you do not wish to accept the changes, you may close your account and terminate this Agreement by either deleting your User Account on the KareHero App or contacting us at ',
            },
            {
                _key: '388f82f8a64d',
                _type: 'span',
                marks: ['3464d4f34a66'],
                text: 'support@karehero.com',
            },
            {
                _key: '9663d410c0cd',
                _type: 'span',
                marks: [],
                text: ' before such changes take effect (subject to yours and our accrued rights under clause 11). Your continued access or use of the KareHero App after the relevant changes have been made will constitute your acceptance of the amended Agreement. The terms of this Agreement cannot be varied otherwise without our express written consent. We reserve the right to cease to provide and/or update content to the KareHero App (including without limit the KareHero Content), without prior notice to you, if we need to do so for security or legal reasons.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [
            {
                _key: '3464d4f34a66',
                _type: 'link',
                href: 'mailto://support@karehero.com',
            },
        ],
        style: 'normal',
    },
    {
        _key: 'b68ce9470e2a',
        _type: 'block',
        children: [
            {
                _key: '188822f8dd2a0',
                _type: 'span',
                marks: ['strong'],
                text: 'Definitions and Interpretation',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '08958437f06b',
        _type: 'block',
        children: [
            {
                _key: 'aab36ad6c7b0',
                _type: 'span',
                marks: [],
                text: 'Unless the context otherwise requires, the following words and expressions shall have the meaning set forth below:\n\n',
            },
            {
                _key: '078a717624500',
                _type: 'span',
                marks: ['strong'],
                text: 'Applicable Law: ',
            },
            {
                _key: '078a717624501',
                _type: 'span',
                marks: [],
                text: 'means all applicable laws, statutes, regulations and codes relating to and including but not limited to bribery, corruption, slavery and human trafficking, the evasion (and facilitation of evasion) of tax, money laundering and fraud, the purchase of goods and services from time to time in force;\n\n',
            },
            {
                _key: 'b5aca29d614e0',
                _type: 'span',
                marks: ['strong'],
                text: 'KareHero App: ',
            },
            {
                _key: 'b5aca29d614e1',
                _type: 'span',
                marks: [],
                text: 'means a) the KareHero application; b) KareHero website and c) CareSourcer website (intended for the Users to access Services, which is operated by KareHero and made available for use by users on desktop and mobile devices (such as mobile phones, tablets and other devices), including any updates to the application that we make available for download from any third party application store;\n\n',
            },
            {
                _key: '3a7bc1b9352b0',
                _type: 'span',
                marks: ['strong'],
                text: 'Intellectual Property Rights: ',
            },
            {
                _key: '3a7bc1b9352b1',
                _type: 'span',
                marks: [],
                text: 'means all intellectual property rights on a world-wide basis whether currently in existence and now known or future or otherwise and whether vested or contingent including (without limitation) copyright (including foreign language translation rights), design rights, database rights, rights in any domain names, registered designs, patents, trade marks, trade names, signs and other designations provided the foregoing are of a proprietary nature and all similar rights whether registered or otherwise (including, without limitation, all extensions, reversions, revivals and renewals thereof). The above shall include, in relation to registrable rights, any applications made or rights to make applications in respect of any such rights;\n\n',
            },
            {
                _key: '9bb371d39f9f0',
                _type: 'span',
                marks: ['strong'],
                text: 'KareHero Content',
            },
            {
                _key: '9bb371d39f9f1',
                _type: 'span',
                marks: [],
                text: ': means any information, images, text, graphics, software, photographs, videos, sound, domain names, media, company name(s), logo, trademarks and any other brand features which are supplied by KareHero to Users and/or submitted to and/ or published on the KareHero App, excluding the User Content (as defined below);\n\n',
            },
            {
                _key: '90a2cf02e7ac0',
                _type: 'span',
                marks: ['strong'],
                text: 'Services: ',
            },
            {
                _key: '90a2cf02e7ac1',
                _type: 'span',
                marks: [],
                text: 'means the following services that are accessible through the KareHero App',
            },
            {
                _key: '90a2cf02e7ac2',
                _type: 'span',
                marks: ['strong'],
                text: ':\n\n',
            },
            {
                _key: '82f6548b15880',
                _type: 'span',
                marks: [],
                text: '(a) a service allowing Users to use the digital tools and human experts that provide advice and information on social care topics, with different types of User memberships available on a subscription basis, as further displayed on the KareHero App (“Concierge Service”);\n\n(b) a directory of registered care providers (registering separately with KareHero) allowing Users to search and select Products to find appropriate care; The purchase and payment for Products to be supplied are arranged directly between the Provider and the User outside the KareHero App;\n\n(c) a digital library of articles containing information on social care topics;\n\n(d) other services related to social care, provision of which may be governed by a separate agreement and displayed on KareHero App.\n\n',
            },
            {
                _key: '9c9673afcc570',
                _type: 'span',
                marks: ['strong'],
                text: 'Product: ',
            },
            {
                _key: '9c9673afcc571',
                _type: 'span',
                marks: [],
                text: 'means the Provider’s products available on the KareHero App in accordance with this Agreement;\n\n',
            },
            {
                _key: '6655e993cc840',
                _type: 'span',
                marks: ['strong'],
                text: 'Provider: ',
            },
            {
                _key: '6655e993cc841',
                _type: 'span',
                marks: [],
                text: 'means a 3rd party services provider which is approved by KareHero to offer Products and Services and displayed by KareHero on the KareHero App;\n\n',
            },
            {
                _key: 'f2e0037e2f6d0',
                _type: 'span',
                marks: ['strong'],
                text: 'Care Provider: ',
            },
            {
                _key: 'f2e0037e2f6d1',
                _type: 'span',
                marks: [],
                text: 'means a care services provider listed on the KareHero App.\n\n',
            },
            {
                _key: '207f09e6bc3e0',
                _type: 'span',
                marks: ['strong'],
                text: 'Territory',
            },
            {
                _key: '207f09e6bc3e1',
                _type: 'span',
                marks: [],
                text: ': means United Kingdom;\n\n',
            },
            {
                _key: 'b86bd624f0910',
                _type: 'span',
                marks: ['strong'],
                text: 'User: ',
            },
            {
                _key: 'b86bd624f0911',
                _type: 'span',
                marks: [],
                text: 'means a person accessing and using the KareHero App, Services and the User Account;\n\n',
            },
            {
                _key: 'b1bc50770ebc0',
                _type: 'span',
                marks: ['strong'],
                text: 'User Account: ',
            },
            {
                _key: 'b1bc50770ebc1',
                _type: 'span',
                marks: [],
                text: 'means a data account in our systems that can be securely accessed and is unique and accessible to you as a KareHero App User;\n\n',
            },
            {
                _key: '817f92c4999a0',
                _type: 'span',
                marks: ['strong'],
                text: 'User Content',
            },
            {
                _key: '817f92c4999a1',
                _type: 'span',
                marks: [],
                text: ': means any information, images or text submitted, shared, transmitted or uploaded by the User to the KareHero App.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '12fc9d9e18ed',
        _type: 'block',
        children: [
            {
                _key: 'db704b715b300',
                _type: 'span',
                marks: ['strong'],
                text: 'Our role',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'd14e45f42c3c',
        _type: 'block',
        children: [
            {
                _key: '26cbe3d61ae4',
                _type: 'span',
                marks: [],
                text: 'When a User selects a Product through the KareHero App, the User purchases from the Provider directly, not from us. \n\nIn the event of any quality issues associated with any Products offered by a Provider to you, all queries and disputes should be directed to the Provider. KareHero has no obligation to monitor or resolve any disputes between Users and Providers, but we may choose to do so at our own sole and absolute discretion.\n\nWe do not endorse, verify, or otherwise certify the Products made available by a Provider, nor the credit worthiness, capability or reliability of any Providers. Any information provided by a Provider to another User is given by the party concerned and we are not responsible for its quality, accuracy, reliability, timeliness or authenticity. Before using such Products, make sure you have read and agreed to the terms on which they are being offered to you including the way in which they may use your personal information.\n\nNotwithstanding anything in the foregoing, you acknowledge and agree that KareHero may use, store, retain and otherwise process the details of any and all orders submitted by Users through the KareHero App (subject to our Privacy Policy).\n\nFrom time to time, we may change or remove the Services, Products, and Providers that are made available through the KareHero App.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'aa6a069f1fcc',
        _type: 'block',
        children: [
            {
                _key: '9ce091c6ceae0',
                _type: 'span',
                marks: ['strong'],
                text: 'Access to the KareHero App and if someone else owns the device you are using',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '805da60762ae',
        _type: 'block',
        children: [
            {
                _key: '5745f4cd1a65',
                _type: 'span',
                marks: [],
                text: 'The KareHero App may only be downloaded, installed, accessed or used on a device owned or controlled by you and running the relevant operating system for which the KareHero app concerned was designed, so you must make sure you have a compatible device which meets all the necessary technical specifications to enable you to download and use the KareHero app. We recommend that you back up any content and data used in connection with the KareHero app to protect yourself in case of problems with the KareHero app. The KareHero app has not been developed to meet your individual requirements. Please check that the functions of the KareHero app meet your requirements.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'd9ac3b6a6098',
        _type: 'block',
        children: [
            {
                _key: '5dec27171c0d',
                _type: 'span',
                marks: [],
                text: 'You and any other individual users who use this KareHero App on your behalf will be assumed to have obtained permission from the owner of any device that is controlled, but not owned, by you to download the KareHero App to that device. You accept responsibility, in accordance with this Agreement, for all access to, and use of, the KareHero App by you, your personnel and other individual users on any device and for complying with this Agreement, whether or not the device is owned by you.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'fd17ac6ccd3d',
        _type: 'block',
        children: [
            {
                _key: '3a83ea95c999',
                _type: 'span',
                marks: [],
                text: 'You acknowledge that the mobile service provider for the device to which you download or on which you access or use the KareHero App, may charge for internet access (including mobile data usage) on that device.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '8a8de8e40fd9',
        _type: 'block',
        children: [
            {
                _key: '74f861b5a697',
                _type: 'span',
                marks: [],
                text: 'We may, from time to time, restrict access to certain features, functions or content of, or the Services accessible through the KareHero App. You must be registered to access or use the KareHero App and the Services and you will be required to provide information or material about your entity and business as part of the registration process. You must ensure that any registration details and information that you provide, whether during the registration process or thereafter throughout the continuation of your use of the KareHero App, are complete, accurate and kept up-to-date. You agree to maintain and promptly amend all such details and information to keep it true, complete, current and accurate.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '281b42cfc7b4',
        _type: 'block',
        children: [
            {
                _key: '988ea0454f7d',
                _type: 'span',
                marks: [],
                text: 'Your User Account on the KareHero App is accessible through a log-on ID (such as a username and password or other identifier). You must safeguard and treat such information as confidential, keeping it secure and safely stored, and must not disclose it to anyone else. You must notify us immediately of any actual or suspected security breach or improper or unauthorised use of your User Account details and password of which you become aware, including without limit any use which would breach this Agreement.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '7396d8e23d19',
        _type: 'block',
        children: [
            {
                _key: 'a42cc38067c5',
                _type: 'span',
                marks: [],
                text: 'We reserve the right to disable any log-on ID or suspend or terminate your User Account or restrict your access to the KareHero App, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of this Agreement or if any details or information you provide for the purposes of registration (or for the purposes of maintaining access to, and use of, the KareHero App and your User Account) prove to be false, or misleading or for any other reason.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '956b61561a86',
        _type: 'block',
        children: [
            {
                _key: 'ea9edd74539a',
                _type: 'span',
                marks: [],
                text: 'You understand and accept that KareHero shall have administrator access to all parts of the KareHero App and will track, using third party tools, your use of the KareHero App. References to the tracking are included in KareHero ’s Privacy Policy.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'a98e765a9385',
        _type: 'block',
        children: [
            {
                _key: '8036a27659ff0',
                _type: 'span',
                marks: ['strong'],
                text: 'Licence and what you are allowed to do',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '24f102a6e7ab',
        _type: 'block',
        children: [
            {
                _key: 'aa5c419ae785',
                _type: 'span',
                marks: [],
                text: 'We licence to you the KareHero App which may only be used by you for personal purposes only and either for yourself or for and on behalf of the individual, and only in accordance with this Agreement, any applicable terms of any relevant third party service provider for the device to which you download, or on which you access or use, the KareHero App and only for lawful purposes (complying with all Applicable Laws), and in a responsible manner.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'd097b81b4a9f',
        _type: 'block',
        children: [
            {
                _key: '5299f2a7e630',
                _type: 'span',
                marks: [],
                text: 'Subject to the following sections, you may retrieve and display content (including without limit the KareHero content) from the KareHero App on a computer or mobile device and store the KareHero App in electronic form incidentally in the normal course of use of your browser or mobile device. Additional Agreements may also apply to certain features, parts or content of the KareHero App and, where they apply, will be displayed on-screen or accessible via a link available for you to read and agree before accessing such content. The provisions of clause 1.2 shall apply in the event of any conflict or inconsistency between the terms of this Agreement and the terms of any Additional Agreement.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'eeaff801b13e',
        _type: 'block',
        children: [
            {
                _key: '9c2ac25ae5f40',
                _type: 'span',
                marks: ['strong'],
                text: 'What you are not allowed to do',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'e779bb5f2a60',
        _type: 'block',
        children: [
            {
                _key: '0d09bf27442f',
                _type: 'span',
                marks: [],
                text: 'While using the KareHero App and the Services, you agree to comply with all Applicable Laws. Except to the extent expressly set out in this Agreement, you shall not:',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '6be80ff16fb1',
        _type: 'block',
        children: [
            {
                _key: '00a2576518bb',
                _type: 'span',
                marks: [],
                text: 'republish, exploit, redistribute or re-transmit the KareHero App;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'abcc9da7c966',
        _type: 'block',
        children: [
            {
                _key: 'b6217b02ab28',
                _type: 'span',
                marks: [],
                text: 'attempt to or actually reverse engineer, decompile, disassemble, decipher or create derivative works based on the whole or any part of the KareHero app or otherwise attempt to derive the source code for the KareHero App or any related technology;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'f900f225fe82',
        _type: 'block',
        children: [
            {
                _key: 'eb1d83e31e5d',
                _type: 'span',
                marks: [],
                text: 'duplicate, copy or store the KareHero App other than for your normal use of the KareHero App as permitted by the terms of this Agreement and as may occur incidentally in the normal course of use of your browser or mobile device;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '5fdd48e975a4',
        _type: 'block',
        children: [
            {
                _key: 'f8be5b5abba8',
                _type: 'span',
                marks: [],
                text: 'store the KareHero App on a server or other storage device connected to a network or create a database by systematically downloading and storing any data from the KareHero App;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '0c262ce10f7e',
        _type: 'block',
        children: [
            {
                _key: '5b5c1e34a25d0',
                _type: 'span',
                marks: [],
                text: 'remove or change any content (including without limit the KareHero Content) of the KareHero App other than applicable KareHero Content;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '236694050229',
        _type: 'block',
        children: [
            {
                _key: '4230ec8796820',
                _type: 'span',
                marks: [],
                text: 'translate, merge, adapt, vary, alter or modify, the whole or any part of the KareHero App nor permit the KareHero App or any part of it to be combined with, or become incorporated in, any other programs, except as necessary to use the KareHero App on devices as permitted by the terms of this Agreement;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '210883b949ad',
        _type: 'block',
        children: [
            {
                _key: 'c150829ddef60',
                _type: 'span',
                marks: [],
                text: 'attempt to circumvent security or technical measures used to provide the KareHero App or interfere with the proper working and functioning of the KareHero App or any servers on which it is hosted or use the KareHero App or any of the Services in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other Users;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '174cfca23c12',
        _type: 'block',
        children: [
            {
                _key: '688d36218b440',
                _type: 'span',
                marks: [],
                text: 'systematically extract and/or re-utilise parts of the KareHero App and, in particular, you must not use any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilisation any substantial parts of the KareHero App;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '1624a11de8f6',
        _type: 'block',
        children: [
            {
                _key: '91ca323f39e20',
                _type: 'span',
                marks: [],
                text: 'intentionally or negligently upload or transmit data, content or material that is false, defamatory, offensive, misleading or otherwise objectionable in relation to your use of the KareHero App or any Services or transmit, store, display, distribute or otherwise make available content that is illegal, fraudulent or harmful to others;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '8e2669228430',
        _type: 'block',
        children: [
            {
                _key: '9b927f2815c30',
                _type: 'span',
                marks: [],
                text: 'use the KareHero App in a way that might damage our name, goodwill or reputation or that of any of our affiliates or licensors;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'a1a50add11e6',
        _type: 'block',
        children: [
            {
                _key: 'b5deec45b0560',
                _type: 'span',
                marks: [],
                text: 'use the KareHero App or any Services in any unlawful manner, for any unlawful purpose or in breach any Applicable Laws, or in any manner inconsistent with the terms of this Agreement, or act fraudulently or maliciously, for example, by hacking into or inserting or distributing malicious code, such as viruses, or harmful data or any other technologies, into the KareHero app, any Services or any operating system;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '4d4325734b7d',
        _type: 'block',
        children: [
            {
                _key: '740c3dce2fc70',
                _type: 'span',
                marks: [],
                text: 'use or access the KareHero App, platform or its account(s) to build a similar service or application or engage in activities which are identical or similar to the Services;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'ff0fa42ccbec',
        _type: 'block',
        children: [
            {
                _key: '8ab6a4a256670',
                _type: 'span',
                marks: [],
                text: 'infringe our Intellectual Property Rights or infringe any Intellectual Property Rights that belong to third parties in relation to your use of the KareHero App or any of the Services, including by the submission of any material (to the extent that such use is not licensed by this Agreement) or post KareHero Content that does not belong to you; or',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '2d0ead9abb65',
        _type: 'block',
        children: [
            {
                _key: '284a0405496b0',
                _type: 'span',
                marks: [],
                text: 'otherwise do anything that is not expressly permitted by the terms of this Agreement or is otherwise agreed in writing between you and us.',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '36b6715f342c',
        _type: 'block',
        children: [
            {
                _key: 'a2d7b51b8390',
                _type: 'span',
                marks: [],
                text: 'If you become aware of any breach of the above terms or any other terms of this Agreement, please report it to ',
            },
            {
                _key: '0cd80c11be93',
                _type: 'span',
                marks: ['509dc17e1270'],
                text: 'support@karehero.com',
            },
            {
                _key: '61e8d9684935',
                _type: 'span',
                marks: [],
                text: ' or by clicking on the relevant link in the KareHero App. You agree to assist us with any investigation we undertake and to take any remedial steps we require to correct a breach of any terms of this Agreement.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [
            {
                _key: '509dc17e1270',
                _type: 'link',
                href: 'mailto://support@karehero.com',
            },
        ],
        style: 'normal',
    },
    {
        _key: '5f95052d2090',
        _type: 'block',
        children: [
            {
                _key: 'a162bd03a034',
                _type: 'span',
                marks: [],
                text: 'You acknowledge and agree that if you wish to do anything with the KareHero App that is not expressly permitted by this Agreement, you will need a separate licence from us. Please contact us via email to ',
            },
            {
                _key: '4a220fd0f692',
                _type: 'span',
                marks: ['4e51b7418eef'],
                text: 'support@karehero.com',
            },
            {
                _key: 'e4618061dece',
                _type: 'span',
                marks: [],
                text: '.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [
            {
                _key: '4e51b7418eef',
                _type: 'link',
                href: 'mailto://support@karehero.com',
            },
        ],
        style: 'normal',
    },
    {
        _key: 'be5028c22807',
        _type: 'block',
        children: [
            {
                _key: '5718a4663c250',
                _type: 'span',
                marks: ['strong'],
                text: 'Third party platform providers and application stores',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '124579aefd66',
        _type: 'block',
        children: [
            {
                _key: 'a90dd1a2a690',
                _type: 'span',
                marks: [],
                text: 'Certain third party platform providers, with whose devices and/or operating systems the KareHero app has been designed to be compatible, oblige us to include certain additional provisions in this Agreement. These are set out at the end of this Agreement under “',
            },
            {
                _key: '94850f0070c01',
                _type: 'span',
                marks: ['c2a910bb26cc'],
                text: 'Additional terms from third party platform providers',
            },
            {
                _key: '94850f0070c02',
                _type: 'span',
                marks: [],
                text: '”. These provisions come from the relevant third party platform providers, not us.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [
            {
                _key: 'c2a910bb26cc',
                _type: 'link',
                href: 'https://s3-eu-west-1.amazonaws.com/website-rekkiapp/terms_and_conditions.html#additional-terms-from-third-party-platform-providers',
            },
        ],
        style: 'normal',
    },
    {
        _key: '974f3d0b5635',
        _type: 'block',
        children: [
            {
                _key: '8755a8c824ea',
                _type: 'span',
                marks: [],
                text: 'Third party application stores are operated by the relevant third party platform providers and/or their affiliates. You must comply with all applicable terms of service, rules and policies applying to any third party application store from which you download the KareHero App. We are not responsible for such stores or (with the exception of the KareHero App) for anything provided by them and do not guarantee that they will be continuously available. No warranty or representation is made with regard to such stores and in no event shall we, or our affiliates, be held liable for any services or products provided by such stores and third party platform providers.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'abd577b1d0fc',
        _type: 'block',
        children: [
            {
                _key: '3aef2ba023db0',
                _type: 'span',
                marks: ['strong'],
                text: 'Intellectual Property Rights',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '540e6d4ee189',
        _type: 'block',
        children: [
            {
                _key: 'aded141222a0',
                _type: 'span',
                marks: [],
                text: 'We licence, but do not sell, to you the KareHero App (including the KareHero Content) you download. We remain the owners of the KareHero App and the KareHero Content at all times.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '8ca4e857da5c',
        _type: 'block',
        children: [
            {
                _key: '84bf6dbe365b',
                _type: 'span',
                marks: [],
                text: 'All rights (including but not limited to all Intellectual Property Rights) to and in the KareHero App and in any KareHero Content on the KareHero App, and all other Intellectual Property Rights belonging to or licensed to KareHero, remain vested in and owned by us, our affiliates or our licensors at all times (together the “',
            },
            {
                _key: '876ab741a1531',
                _type: 'span',
                marks: ['strong'],
                text: 'KareHero IP',
            },
            {
                _key: '876ab741a1532',
                _type: 'span',
                marks: [],
                text: '”). Except as expressly set out here, nothing in this Agreement gives you any rights in respect of any KareHero IP or other Intellectual Property Rights owned by us, our affiliates or our licensors or of the goodwill associated therewith. You acknowledge that you do not acquire any ownership rights by downloading the KareHero App or any KareHero Content from the KareHero App and that you have no Intellectual Property Rights in, or to, the KareHero App other than the right to use the KareHero App in accordance with the terms of this Agreement.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '03da58f5dba6',
        _type: 'block',
        children: [
            {
                _key: '464af96ec6d5',
                _type: 'span',
                marks: [],
                text: 'The KareHero App may contain code, commonly referred to as open source software, which is distributed under any of the many known variations of open source licence terms, including terms which allow the free distribution and modification of the relevant software’s source code and/or which require all distributors to make such source code freely available upon request, including any contributions or modifications made by such distributor (collectively, “',
            },
            {
                _key: 'f34c98316fc01',
                _type: 'span',
                marks: ['strong'],
                text: 'Open Source Software”',
            },
            {
                _key: 'f34c98316fc02',
                _type: 'span',
                marks: [],
                text: '). Please note that, to the extent that the KareHero app contains any Open Source Software, that element only is licensed to you under the relevant licence terms of the applicable third party licensor (“',
            },
            {
                _key: 'f34c98316fc03',
                _type: 'span',
                marks: ['strong'],
                text: 'Open Source Licence Terms”',
            },
            {
                _key: 'f34c98316fc04',
                _type: 'span',
                marks: [],
                text: ') and not under this Agreement, and you accept and agree to be bound by such Open Source Licence Terms. A copy of the source code for any Open Source Software contained in the KareHero App and the relevant Open Source Licence Terms will be made available to you upon written request to us.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'fb4ee1c31496',
        _type: 'block',
        children: [
            {
                _key: '7df27b3f44af',
                _type: 'span',
                marks: [],
                text: 'Nothing in the terms of this Agreement shall constitute any representation or warranty by us that: (a) the Intellectual Property rights in KareHero IP are valid; or (b) the exercise by KareHero s of rights granted under this Agreement will not infringe the rights (including any third party Intellectual Property Rights) of any person.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '18b750acec42',
        _type: 'block',
        children: [
            {
                _key: 'da9c67cea87d0',
                _type: 'span',
                marks: ['strong'],
                text: 'Functionality, content and updates to the App',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'ae96b6c7fbad',
        _type: 'block',
        children: [
            {
                _key: '321f812bcb7b',
                _type: 'span',
                marks: [],
                text: "You agree that downloading, installing, accessing and use of the KareHero App (including all material and all KareHero Content) that is made available for download and all related Services are provided on an 'as is' and 'as available' basis and at your sole discretion and risk. Updates will either download automatically or you may need to trigger them yourself, depending on your device and its settings.",
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '28efd443dc77',
        _type: 'block',
        children: [
            {
                _key: '3b60cb52f5d9',
                _type: 'span',
                marks: [],
                text: 'We reserve the right to change the design, features and/or functionality of the KareHero App by making the updated KareHero App available for you to download or, where your device settings permit it, by automatic delivery of updates. You are not obliged to download any updated KareHero app, but we may cease to provide and/or update content to prior versions of the KareHero App and, depending on the nature of the update, in some circumstances you may not be able to continue using the KareHero App until you have downloaded the updated version.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '5e94f4b1ef33',
        _type: 'block',
        children: [
            {
                _key: '32ab34d22c81',
                _type: 'span',
                marks: [],
                text: 'Whilst we try to make sure that KareHero Content made available by the KareHero App consisting of information of which we are the source is correct, you acknowledge that the KareHero App makes content available which is derived from a number of sources – including from Providers for which we are not responsible. In all cases, information made available by the KareHero App (including the KareHero Content) is not intended to amount to authority or advice on which reliance should be placed. Accordingly, any material or content downloaded or otherwise obtained through the KareHero App is done at each User’s sole discretion and risk.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '50b730783831',
        _type: 'block',
        children: [
            {
                _key: '28c080955ef8',
                _type: 'span',
                marks: [],
                text: 'Except as expressly set out in this Agreement, and to the extent permitted by law, we make or give no representation or warranty:',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '30cb6fed0c81',
        _type: 'block',
        children: [
            {
                _key: 'eeae26bb2d5f',
                _type: 'span',
                marks: [],
                text: 'as to the validity, accuracy, completeness, currency, correctness, reliability, integrity, quality, performance, timeliness, merchantability or originality of any content of the KareHero App;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'b8cbaebd2622',
        _type: 'block',
        children: [
            {
                _key: '599227693a2f',
                _type: 'span',
                marks: [],
                text: 'that the KareHero App will be fit for purpose and meet your specific requirements;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'f5bf7683452a',
        _type: 'block',
        children: [
            {
                _key: 'e25bee1e4b59',
                _type: 'span',
                marks: [],
                text: 'as to non-infringement or originality of any KareHero Content of the KareHero App;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'a503202854ce',
        _type: 'block',
        children: [
            {
                _key: 'b1901022f261',
                _type: 'span',
                marks: [],
                text: 'as to any Services offered or displayed on the KareHero App.',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'c887f13a1023',
        _type: 'block',
        children: [
            {
                _key: '92cdcebe9cbc',
                _type: 'span',
                marks: [],
                text: 'as to the continuous, uninterrupted or error-free operability of, or access to, the KareHero App or that the KareHero App will respond at a certain speed (since this depends on a number of factors outside our control); or',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '03c0969cc313',
        _type: 'block',
        children: [
            {
                _key: '3623f132d070',
                _type: 'span',
                marks: [],
                text: 'that all errors in the KareHero App will be corrected,\n\nand we hereby expressly disclaim all such warranties and, to the fullest extent permitted by law, all implied warranties, conditions or other terms of any kind are also hereby excluded. To the fullest extent permitted by law, we accept no liability for any loss or damage of any kind incurred as a result of you or anyone else relying on the content of the KareHero App (including the KareHero Content).',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '08f5fa21d519',
        _type: 'block',
        children: [
            {
                _key: 'cfb9158381da',
                _type: 'span',
                marks: [],
                text: 'We cannot and do not guarantee that the Services, the KareHero Content, the KareHero App will be free from viruses and/or other code that may have contaminating or destructive elements. It is your responsibility to implement appropriate IT security safeguards (including anti-virus and other security checks) to satisfy your requirements as to the safety and reliability of the KareHero App and its content.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'd066c4e49026',
        _type: 'block',
        children: [
            {
                _key: '499e1fbc61d8',
                _type: 'span',
                marks: [],
                text: 'All KareHero Content, including any images of products shown by Providers, may not represent the actual Services and/or Products offered.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '38aab31b90d3',
        _type: 'block',
        children: [
            {
                _key: '2d8f4bf038e00',
                _type: 'span',
                marks: ['strong'],
                text: 'Your personal information',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'b2a013503f6c',
        _type: 'block',
        children: [
            {
                _key: '505de5d730f8',
                _type: 'span',
                marks: [],
                text: 'Use of your personal information submitted to us via the KareHero App is governed by our Privacy Policy. Additionally, by using the KareHero App, you acknowledge that internet transmissions are never completely private or secure and that it is always possible that any message or information you send using the KareHero App may be read or intercepted by others.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '171c37b259be',
        _type: 'block',
        children: [
            {
                _key: 'e4b5b68625490',
                _type: 'span',
                marks: ['strong'],
                text: 'User Content',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '1bbf4f9d95f7',
        _type: 'block',
        children: [
            {
                _key: '4b9d592ff405',
                _type: 'span',
                marks: [],
                text: 'We do not control or screen User Content. You are solely responsible for User Content as submitted, shared, transmitted, or uploaded by you. Accordingly, we do not endorse, verify or otherwise certify the contents of any comments, communications, material or information submitted, shared, transmitted or uploaded and take no responsibility and assume no liability for any User Content that you post, send, or otherwise make available through the App.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '98ebe9a9755b',
        _type: 'block',
        children: [
            {
                _key: '74d725bf8889',
                _type: 'span',
                marks: [],
                text: 'If you submit, share, transmit or upload any User Content, you must:',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '5fada2223571',
        _type: 'block',
        children: [
            {
                _key: '00fc074555e40',
                _type: 'span',
                marks: [],
                text: 'not submit any User Content that is unlawful, threatening or harassing, defamatory, abusive, libellous, pornographic, obscene, objectionable, vulgar, indecent or offensive.',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '192cbdd027bd',
        _type: 'block',
        children: [
            {
                _key: 'df2b1e4faa670',
                _type: 'span',
                marks: [],
                text: 'not submit any User Content which infringes the Intellectual Property Rights or other rights of any third party;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '9ca76c4aedc5',
        _type: 'block',
        children: [
            {
                _key: '7a618337f8ee0',
                _type: 'span',
                marks: [],
                text: 'not submit any User Content that breaches any Applicable Law or that you consider to be confidential or proprietary in nature (as further described in clause 11.4 below); and',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '9f9689ad2fa3',
        _type: 'block',
        children: [
            {
                _key: '00124f445cf80',
                _type: 'span',
                marks: [],
                text: 'not submit any User Content that contains any viruses and/or other code that has contaminating or destructive elements.',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '08a5b0ac1e25',
        _type: 'block',
        children: [
            {
                _key: '8773ec640fea',
                _type: 'span',
                marks: [],
                text: 'You agree that, by submitting any User Content, you grant us and our affiliates a perpetual, irrevocable, worldwide, non-exclusive, royalty-free and fully sub-licensable right (through multiple tiers) and licence to transmit, use, reproduce, modify, adapt, edit, publish, duplicate, translate, create derivative works from, distribute, perform and display such User Content (in whole or part), remove any part of it and/or incorporate it in other works in any form, media or technology known now or developed in the future in any manner and for any purpose which may be beneficial, whether directly or indirectly, to us, our affiliates and the KareHero App including without limitation to provide the Services we offer through the KareHero App. You waive any moral rights you may have in, or to be identified as the author, of such User Content.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '29c42b507d74',
        _type: 'block',
        children: [
            {
                _key: '0b1a7fc8f687',
                _type: 'span',
                marks: [],
                text: 'Any User Content posted by you to or through the KareHero App or transmitted to, or shared with, us will be considered non-confidential and non-proprietary, and treated as such by us, and may be used by us in accordance with this Agreement (including and subject to our Privacy Policy) without notice to you and without any liability. We will not use User Content for marketing purposes to the extent it incorporates any order information submitted by Users to the KareHero App without your prior consent (not to be unreasonably withheld or delayed).',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'b1d08e89498f',
        _type: 'block',
        children: [
            {
                _key: 'bfa58d822a06',
                _type: 'span',
                marks: [],
                text: 'Whilst we do not pre-screen User Content, we reserve the right, in our sole discretion, to remove, delete, edit or modify any User Content (in whole or in part) submitted by you and/or to close any discussion topic or chat, at any time without notice or liability to you including without limitation which we reasonably believe: (a) breaches any Applicable Laws; (b) infringes any third party Intellectual Property Rights; (c) could harm our interests or the interests of our affiliates or licensors; or (d) otherwise find is inappropriate in our sole discretion.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'dc60472210c1',
        _type: 'block',
        children: [
            {
                _key: '55200ad4e387',
                _type: 'span',
                marks: [],
                text: 'You warrant, represent and undertake that you have all rights, consents, authority, power and permissions necessary to meet your obligations under this Agreement, to grant the licence referred to in clause 11.3 and required by us to use any of the User Content.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '725d83ad1ad8',
        _type: 'block',
        children: [
            {
                _key: 'f0d550d03b10',
                _type: 'span',
                marks: [],
                text: 'Complaints about the content of any User Content must be sent to ',
            },
            {
                _key: '96ad29e155191',
                _type: 'span',
                marks: ['f4d3311eec52'],
                text: 'support@karehero.com',
            },
            {
                _key: '96ad29e155192',
                _type: 'span',
                marks: [],
                text: ' and must contain details of the specific User Content giving rise to the complaint.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [
            {
                _key: 'f4d3311eec52',
                _type: 'link',
                href: 'mailto://support@karehero.com',
            },
        ],
        style: 'normal',
    },
    {
        _key: '4bde593a5c4d',
        _type: 'block',
        children: [
            {
                _key: '6b2cd9bdc7970',
                _type: 'span',
                marks: ['strong'],
                text: 'External links',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'a57f02c611f0',
        _type: 'block',
        children: [
            {
                _key: 'b013cfff0afe',
                _type: 'span',
                marks: [],
                text: 'The KareHero App may, from time to time, include links to external, independent sites which are not provided by us and not under our control, which may include links to third party offers and promotions. These are included to provide you with access to information, products or services that you may find useful or interesting. We are not responsible for, and have not checked and approved, the content of these sites or for anything provided by them and do not guarantee that they will be continuously available. The fact that links are included to such external sites does not imply any endorsement of or association with their operators or promoters. You will need to make your own independent judgement about whether to use any such external, independent sites, including whether to buy any products or services offered by them.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '12a3b5dd48a5',
        _type: 'block',
        children: [
            {
                _key: 'e91de72f74c70',
                _type: 'span',
                marks: ['strong'],
                text: 'Termination of your rights to use the KareHero App and effects of termination',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'a900cd5fb390',
        _type: 'block',
        children: [
            {
                _key: '130a8994315d',
                _type: 'span',
                marks: [],
                text: 'Without affecting any other right or remedy available to us, we may terminate this Agreement with immediate effect by giving written notice to you in the event that:',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '72306c97dfbc',
        _type: 'block',
        children: [
            {
                _key: 'b394b0faf885',
                _type: 'span',
                marks: [],
                text: 'you are in material or persistent breach of any of these terms or conditions of this Agreement;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'da99412eedbf',
        _type: 'block',
        children: [
            {
                _key: '5e12d0840bbe',
                _type: 'span',
                marks: [],
                text: 'we have reasonable grounds to believe that you are in material or persistent breach of any of these terms or conditions of this Agreement, which you fail to remedy (if remediable) within seven (7) days after the service of written notice requiring you to do so. This includes without limitation any breach by you of any of the restrictions set out in clause 6.1. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '17aa38811762',
        _type: 'block',
        children: [
            {
                _key: '3f009773600c',
                _type: 'span',
                marks: [],
                text: 'you suspend, or threaten to suspend, payment of your debts or are unable to pay your debts as they fall due or admit inability to pay your debts; or',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '14533bba09c6',
        _type: 'block',
        children: [
            {
                _key: '80b467a67117',
                _type: 'span',
                marks: [],
                text: 'your financial position deteriorates to such an extent that in our reasonable opinion your capability to adequately fulfil your obligations under this Agreement has been placed in jeopardy.',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'ecb53d2c5106',
        _type: 'block',
        children: [
            {
                _key: '721f205a7e42',
                _type: 'span',
                marks: [],
                text: 'Without affecting any other right or remedy available to us, and except where you are in material breach of any of the terms and conditions of this Agreement and clauses 14.1(a) and 14.1(b) apply, we may, at any time, give written notice of not less than one (1) week that your User Account and/or access to the KareHero App and the Services is to be terminated.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '19c3088ad9f8',
        _type: 'block',
        children: [
            {
                _key: '7936e8f4adae',
                _type: 'span',
                marks: [],
                text: 'You may, at any time, choose to stop using the KareHero App and the Services and if this occurs, you must contact us and request in writing deletion of your User Account. Once your User Account has been deleted, the KareHero Content associated with your User Account will no longer be visible on the KareHero App and the provisions of clause 14.4 shall apply. Our Privacy Policy sets out how any personal data will be handled by KareHero, following the deletion of your User Account request.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'e8294322e15a',
        _type: 'block',
        children: [
            {
                _key: 'd4bf9d31696f',
                _type: 'span',
                marks: [],
                text: 'On termination of this Agreement for any reason:',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'd208a0bf6999',
        _type: 'block',
        children: [
            {
                _key: '0fc33b8c32ac',
                _type: 'span',
                marks: [],
                text: 'your User Account will be immediately closed and you will no longer have access to the KareHero App (and any accounts determined to be related to such account by KareHero in its sole discretion and without liability for any losses or damages arising out of or in connection with such termination) or to any data, messages, files or other materials you previously were able to access on the KareHero App and your status as a User will be removed. We will have no obligation to maintain any data, information or content stored in your User Account or on the KareHero App and may, in our sole discretion, delete all copies of such data, information and content in our systems or otherwise in our possession or control except to the extent permitted or required by Applicable Law;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'f8d272e428a3',
        _type: 'block',
        children: [
            {
                _key: 'efe6584f398e0',
                _type: 'span',
                marks: [],
                text: 'all rights granted to you under this Agreement shall immediately cease including your rights to use the KareHero App;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '6989c13f5882',
        _type: 'block',
        children: [
            {
                _key: 'fc789902571a0',
                _type: 'span',
                marks: [],
                text: 'you must stop all activities authorised by this Agreement, including your use of the KareHero App, the KareHero Content and any Services;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'fd8e69589ff7',
        _type: 'block',
        children: [
            {
                _key: 'ab43af2ac65c0',
                _type: 'span',
                marks: [],
                text: 'you must delete or remove the KareHero App and all KareHero Content from all devices in your possession and immediately destroy all copies of the KareHero App and any KareHero Content which you have and confirm to us that you have done this;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '0c7bc6db00d8',
        _type: 'block',
        children: [
            {
                _key: '9d5d6bd820a7',
                _type: 'span',
                marks: [],
                text: 'any outstanding sums due and payable by you to us and by us to you must be made immediately on the effective termination date and we may set-off such sums in our sole and absolute discretion.',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '5d65f61f1fd0',
        _type: 'block',
        children: [
            {
                _key: 'b9e74e41ca80',
                _type: 'span',
                marks: [],
                text: 'Any provision of this Agreement, which is expressly or by implication intended to continue in force and effect after termination of this Agreement shall continue in force and effect notwithstanding such termination, including without limitation clauses 2, 6, 8, 9, 11, 13, 14, 15,16 and 17.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'afe9d19515ef',
        _type: 'block',
        children: [
            {
                _key: '7220096a4427',
                _type: 'span',
                marks: [],
                text: 'The termination of this Agreement shall not prejudice or affect any right of action, remedies, obligations or liabilities of the parties which shall have accrued up to the date of termination, including the right to claim damages in respect of any breach of this Agreement which existed at or before the date of termination.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '971a79219d24',
        _type: 'block',
        children: [
            {
                _key: 'b6da59d55ef80',
                _type: 'span',
                marks: ['strong'],
                text: 'Our liability',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '1f10620d0e05',
        _type: 'block',
        children: [
            {
                _key: '83d2c6277386',
                _type: 'span',
                marks: [],
                text: 'Nothing in this Agreement shall limit or exclude the liability of either party for:',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'f2595c9e2b86',
        _type: 'block',
        children: [
            {
                _key: 'a91857b2db3e',
                _type: 'span',
                marks: [],
                text: 'death or personal injury caused by its negligence or the negligence of its employees, agents or subcontractors;',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '810efb2c365c',
        _type: 'block',
        children: [
            {
                _key: 'f55f230be5b0',
                _type: 'span',
                marks: [],
                text: 'fraud or fraudulent misrepresentation; ',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '458fa3536863',
        _type: 'block',
        children: [
            {
                _key: 'e8ed649dca9a',
                _type: 'span',
                marks: [],
                text: 'our respective contractual debt obligations (including any invoices payable by you); or',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '075124d9dc0c',
        _type: 'block',
        children: [
            {
                _key: '2ed9f7b5ec45',
                _type: 'span',
                marks: [],
                text: 'any other liability that, by law, may not be limited or excluded.',
            },
        ],
        level: 3,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'c5972804464a',
        _type: 'block',
        children: [
            {
                _key: 'd34a73c11262',
                _type: 'span',
                marks: [],
                text: 'In no event shall either you or us be liable for any indirect, special, incidental, punitive or consequential losses or damages, or for any loss of profits or revenues (whether direct or indirect), loss of business, loss of business opportunity, loss of contracts, loss of data, loss of goodwill or reputation or other similar losses or business interruption, howsoever caused and whether in contract, tort (including negligence), strict liability, equity or otherwise, whether or not they were foreseeable or either party was advised of the possibility of such damages or losses, arising out of, or in any way connected with, the Agreement.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'c704968df5f6',
        _type: 'block',
        children: [
            {
                _key: '0adad126df10',
                _type: 'span',
                marks: [],
                text: 'Subject to clauses 14.1 and 14.2, the total aggregate liability of us, our directors, employees, consultants, agents, licensors, affiliates or anyone acting on our behalf under this Agreement in contract, tort (including negligence), strict liability, equity or for breach of statutory duty or otherwise, shall be limited to £100 (one hundred pounds) and strictly limited to losses and damages that were reasonably foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you accepted this Agreement, both we and you knew it might happen. The limitations and exclusions of liability under this Agreement shall apply to the maximum extent permitted by law and shall apply whether or not a party has been advised of, or should have been aware of, the possibility of such losses or damages arising.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '5cf4fc085288',
        _type: 'block',
        children: [
            {
                _key: '492026f9cc6d',
                _type: 'span',
                marks: [],
                text: 'We shall not be in any way responsible or have any liability for the performance of contract between you and any Provider, for the quality, safety, lawfulness or availability of any products advertised by the Provider. We do not provide any guarantee that any communications made between the User and Providers will be received in a timely manner, or that they will be accurate. Users are solely responsible and liable for the contents of their communications and all other material or information which are or are not made via the KareHero App.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'e6b27999acec',
        _type: 'block',
        children: [
            {
                _key: 'a3134ddd30c7',
                _type: 'span',
                marks: [],
                text: 'We shall have no liability to any User of the KareHero App for any loss suffered by a User to the extent that it arises in connection with any communications which are or are not made via the KareHero App nor any liability that may arise as a result of or in connection with claims from such Users or other third parties from their use of the Services.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '43393c39beb1',
        _type: 'block',
        children: [
            {
                _key: 'b9f3a16c4346',
                _type: 'span',
                marks: [],
                text: 'You are responsible for, and KareHero shall not be liable for any losses or damages arising as a result of, your use of the KareHero App and for all activities that occur under your login ID and User Account (whether such use or activities are authorised or not) including for your failure to follow KareHero’s reasonable instructions given from time to time and/or for your breach of Applicable Law.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'e79242335fe5',
        _type: 'block',
        children: [
            {
                _key: '750c73783f420',
                _type: 'span',
                marks: ['strong'],
                text: 'General',
            },
        ],
        level: 1,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '63438b9a0df9',
        _type: 'block',
        children: [
            {
                _key: '535ca08814a1',
                _type: 'span',
                marks: [],
                text: 'You may not transfer or assign or deal in any other manner with, in whole or in part, any or all of your rights or obligations under this Agreement. Any attempted assignment or transfer in breach of this clause will be null and void. We may at any time transfer, assign, subcontract, delegate or deal in any other manner with, in whole or in part, any or all of our rights and obligations under this Agreement (including any affiliates of KareHero).',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '95971a120a0d',
        _type: 'block',
        children: [
            {
                _key: '0a67a0f19d2e',
                _type: 'span',
                marks: [],
                text: 'You hereby warrant, represent and undertake to KareHero that: (i) you will comply with all Applicable Laws; (ii) neither you nor your agents, directors, employees, officers and subcontractors have been convicted of any offence involving any applicable laws, regulations, rules and codes making provision about slavery, servitude and forced or compulsory labour and about human trafficking including but not limited to the Modern Slavery Act 2015 (“',
            },
            {
                _key: '8adc2eed23251',
                _type: 'span',
                marks: ['strong'],
                text: 'Anti-Slavery Laws',
            },
            {
                _key: '8adc2eed23252',
                _type: 'span',
                marks: [],
                text: '”); (iii) neither you nor your agents, directors, employees, officers and subcontractors have engaged in any activity, practice or conduct which would constitute an offence under sections 1, 2 or 6 of the UK Bribery Act 2010 (“',
            },
            {
                _key: '8adc2eed23253',
                _type: 'span',
                marks: ['strong'],
                text: 'Anti-Bribery Laws',
            },
            {
                _key: '8adc2eed23254',
                _type: 'span',
                marks: [],
                text: '”); (iv) having made reasonable enquiries, so far as you are aware, neither you nor your agents, directors, employees, officers and subcontractors have been or are the subject of any investigation, inquiry or enforcement proceedings by any governmental, administrative or regulatory body regarding any offence or alleged offence of or in connection with the Anti-Slavery Laws or Anti-Bribery Laws; and (v) you will have, maintain and continually enforce its own policies and procedures to ensure compliance with your obligations under this clause.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '174b2830b6b9',
        _type: 'block',
        children: [
            {
                _key: '743cf099709d',
                _type: 'span',
                marks: [],
                text: 'All notices given by you to us must be given in writing to ',
            },
            {
                _key: 'fa5e4f876016',
                _type: 'span',
                marks: ['25e4fcd6dfd8'],
                text: 'support@karehero.com',
            },
            {
                _key: 'ab8a2ee66f80',
                _type: 'span',
                marks: [],
                text: '. Notices sent by registered post or recorded delivery shall be deemed to be served three (3) business days following the day of posting. In all other cases, notices are deemed to be served on the day when they are received.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [
            {
                _key: '25e4fcd6dfd8',
                _type: 'link',
                href: 'mailto://support@karehero.com',
            },
        ],
        style: 'normal',
    },
    {
        _key: 'c1a93387676f',
        _type: 'block',
        children: [
            {
                _key: '78ad45eae371',
                _type: 'span',
                marks: [],
                text: 'No party shall be liable or responsible for the failure to perform, or any delay in performance of, any obligation hereunder for causes or events beyond its reasonable control including but not limited to acts of God, acts of government, governmental restrictions and regulations or restrictions imposed by law, accidents, fires, floods, pandemics, epidemics, civil unrest, wars, riots, acts of terror, rebellions, blockades, strikes or other industrial action, computer attacks or malicious acts, such as attacks on or through the Internet, any Internet service or telecommunications provider or telecommunications or internet failures. If support for the KareHero App is delayed by an event outside of our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. If there is a risk of substantial delay, you may contact us to close your User Account and the provisions of clause 13.4 shall apply.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '7316c8c1812a',
        _type: 'block',
        children: [
            {
                _key: '13cec7fbc796',
                _type: 'span',
                marks: [],
                text: 'If we fail to enforce any term of or any of our rights pursuant to the terms of this Agreement, that does not result in a waiver of that term or right and shall in no way affect our right later to enforce or to exercise it.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '209842989f8b',
        _type: 'block',
        children: [
            {
                _key: '73aee1318b60',
                _type: 'span',
                marks: [],
                text: 'If any provision of this Agreement is found to be illegal, invalid or unenforceable under any Applicable Law, such provision shall, insofar as it is severable from the remaining provisions, be deemed omitted from the terms of this Agreement and the legality, validity or enforceability of the remaining provisions shall remain unaffected.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '352eebd6083c',
        _type: 'block',
        children: [
            {
                _key: '2dfaf6484900',
                _type: 'span',
                marks: [],
                text: 'Headings are for reference purposes only and do not define or limit the scope or extent of such clause.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'bfb36548bbb7',
        _type: 'block',
        children: [
            {
                _key: '1dbd04e0a7e3',
                _type: 'span',
                marks: [],
                text: 'The terms of this Agreement other document expressly referred to in them represent the entire agreement between you and us in relation to their subject matter and supersedes any prior agreement, understanding or arrangement between the parties, whether oral or in writing. Any contract formed on the terms of this Agreement may be concluded in the English language only.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'a7273e16ddc5',
        _type: 'block',
        children: [
            {
                _key: 'f4b98e4ad9e9',
                _type: 'span',
                marks: [],
                text: 'Except as may be expressly stated, nothing in this Agreement is intended to confer a benefit on any person who is not a party to it and no such person has any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this Agreement.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: 'adea068c95dc',
        _type: 'block',
        children: [
            {
                _key: 'f2b8e8da03f9',
                _type: 'span',
                marks: [],
                text: 'Except as expressly provided in this Agreement, nothing in this Agreement shall be construed to create an agency relationship between any of the parties.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '1aabc2401265',
        _type: 'block',
        children: [
            {
                _key: '481dfb32e2c5',
                _type: 'span',
                marks: [],
                text: 'This Agreement and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed and construed in accordance with the law of England and Wales. Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this Agreement or its subject matter or formation. Nothing shall prevent us from bringing proceedings to protect our Intellectual Property Rights before any competent court.',
            },
        ],
        level: 2,
        listItem: 'number',
        markDefs: [],
        style: 'normal',
    },
    {
        _key: '1ed1894d4a58',
        _type: 'block',
        children: [
            {
                _key: 'bb2778754b190',
                _type: 'span',
                marks: [],
                text: '',
            },
        ],
        markDefs: [],
        style: 'normal',
    },
];
export default terms;
