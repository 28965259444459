import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { NavVertical } from "..";
// hooks/
import { useWindowSize } from "../../../hooks";
// theme
import theme from "../../../theme";
import { Icon } from "../../atoms";
/**
 * NavDrawer is a navigation drawer that slides in from the left side of the
 * screen.
 */
export const NavDrawer = ({ className, imgSrc, fallback, name, role, links, currentLink, isCollapsed: isCollapsedProp, onToggleCollapse, }) => {
    // hooks/
    const windowSize = useWindowSize();
    // memo
    const isMobile = useMemo(() => {
        return windowSize[0] <= theme.breakpoint.mdValue;
    }, [windowSize]);
    const isCollapsed = useMemo(() => {
        return isMobile || isCollapsedProp;
    }, [isCollapsedProp, isMobile]);
    return (_jsxs(StyledNavDrawer, Object.assign({ className: className, "$isCollapsed": isCollapsed }, { children: [_jsx(Header, { children: !isMobile && (_jsx(CollapseContainer, Object.assign({ "$isCollapsed": isCollapsed }, { children: _jsx(CollapseButton, Object.assign({ onClick: () => onToggleCollapse === null || onToggleCollapse === void 0 ? void 0 : onToggleCollapse() }, { children: _jsx(Icon, { icon: isCollapsed ? 'chevrons-right' : 'chevrons-left' }) })) }))) }), _jsx(NavVertical, { imgSrc: imgSrc, fallback: fallback, name: name, role: role, links: links, currentLink: currentLink, isCollapsed: isCollapsed })] })));
};
const StyledNavDrawer = styled.div `
  position: relative;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  background: ${(props) => props.theme.color.neutral[20]};
  padding: ${(props) => props.theme.spacing[48]}
    ${(props) => props.theme.spacing[16]};
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid
    ${(props) => props.theme.color.illustration.eggBlueLight};
  ${(props) => !props.$isCollapsed &&
    css `
      min-width: 300px;
      width: 300px;
      padding: ${props.theme.spacing[48]} ${props.theme.spacing[24]};
    `}
`;
const CollapseContainer = styled.div `
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: ${(props) => props.theme.spacing[12]};
  box-sizing: border-box;
  ${(props) => props.$isCollapsed &&
    css `
      justify-content: center;
    `}
`;
const CollapseButton = styled.button `
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.neutral[70]};
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
NavDrawer.displayName = 'NavDrawer';
export default NavDrawer;
