import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';

const careProvidedTypes = ['Residential', 'Nursing', 'Dementia', 'Palliative'];

const distances = [5, 10, 20];

const regulatorRatings = {
  ci: [
    { label: 'Excellent', weighting: 6 },
    { label: 'Very good', weighting: 5 },
    { label: 'Good', weighting: 4 },
    { label: 'Adequate', weighting: 3 },
    { label: 'Weak', weighting: 2 },
    { label: 'Unsatisfactory', weighting: 1 },
    { label: 'Not assessed', weighting: 0 },
  ],
  cqc: [
    { label: 'Outstanding', weighting: 6 },
    { label: 'Good', weighting: 4 },
    { label: 'Requires improvement', weighting: 2 },
    { label: 'Inadequate', weighting: 1 },
    { label: 'Registered - awaiting routine inspection', weighting: 0 },
  ],
};

const homeCareHourlyRateRanges = [
  { label: 'Up to £15', value: 0 },
  { label: '£15 - £20', value: 1 },
  { label: '£20 - £25', value: 2 },
  { label: '£25 or more', value: 3 },
];

const homeCareVisitDurations = [
  { label: 'Any duration', value: 'on' },
  { label: '60 minutes or less', value: '60' },
  { label: '45 minutes or less', value: '45' },
  { label: '30 minutes or less', value: '30' },
  { label: '15 minutes', value: '15' },
];

const sortOrders = {
  homeCare: [
    { label: 'Most relevant', value: 'relevance' },
    { label: 'Highest price', value: 'cah_starting_hourly_rate_desc' },
    { label: 'Lowest price', value: 'cah_starting_hourly_rate_asc' },
    { label: 'Highest rating', value: 'regulators_rating' },
  ],
  careHome: [
    { label: 'Most relevant', value: 'relevance' },
    { label: 'Nearest to me', value: 'distance' },
    { label: 'Highest rating', value: 'regulators_rating' },
  ],
};

export const ProviderFilterContext = createContext();

export const ProviderFilterProvider = ({ children }) => {
  const initialCareProvidedState = careProvidedTypes.reduce(
    (all, current) => ({ ...all, [current]: false }),
    {},
  );
  const [careProvidedFilters, setCareProvidedFilters] = useState(
    initialCareProvidedState,
  );
  const [currentDistance, setDistance] = useState(distances[0]);
  const [enabledRegulatorRatings, setEnabledRatings] = useState({});
  const [enabledHourlyRates, setEnabledHourlyRates] = useState({});
  const [enabledCareVisitDuration, setCareVisitDuration] = useState(
    homeCareVisitDurations[0].value,
  );
  const [enabledSortOrder, setEnabledSortOrder] = useState(
    sortOrders.careHome[0].value,
  );

  const toggleCareProvidedFilter = (name) =>
    setCareProvidedFilters({
      ...careProvidedFilters,
      [name]: !careProvidedFilters[name],
    });

  const toggleRegulatorRating = (weighting) =>
    setEnabledRatings({
      ...enabledRegulatorRatings,
      [weighting]: !enabledRegulatorRatings[weighting],
    });

  const toggleHourlyRate = (name) =>
    setEnabledHourlyRates({
      ...enabledHourlyRates,
      [name]: !enabledHourlyRates[name],
    });

  const setCurrentCareVisitDuration = setCareVisitDuration;
  const setCurrentDistance = compose(setDistance, Number);
  const setCurrentSortOrder = setEnabledSortOrder;

  const enabledCareProvidedFilters = Object.keys(careProvidedFilters).filter(
    (filter) => careProvidedFilters[filter],
  );

  const activeFilters = {
    careProvidedFilters,
    currentDistance,
    enabledRegulatorRatings,
    enabledHourlyRates,
    enabledCareVisitDuration,
    enabledSortOrder,
    enabledCareProvidedFilters,
  };

  return (
    <ProviderFilterContext.Provider
      value={{
        activeFilters,
        careProvidedTypes,
        toggleCareProvidedFilter,
        distances,
        setCurrentDistance,
        regulatorRatings,
        toggleRegulatorRating,
        homeCareHourlyRateRanges,
        toggleHourlyRate,
        homeCareVisitDurations,
        setCurrentCareVisitDuration,
        sortOrders,
        setCurrentSortOrder,
      }}
    >
      {children}
    </ProviderFilterContext.Provider>
  );
};

ProviderFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
