import api from '.';
import {
  Account,
  AdminAddInviteKeys,
  AdminInviteMemberRequest,
  AddInviteKeys,
  InviteMemberRequest,
  Organization,
  OrganizationInfo,
  OrganizationType,
} from '@karehero/models';

export const organizationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrganization: builder.query<Organization[], void>({
      query: () => `/organization`,
      providesTags: ['Organizations'],
    }),
    getOrganization: builder.query<Organization, string>({
      query: (id) => `/organization/${id}`,
      providesTags: ['Organizations'],
    }),
    getSessionOrganization: builder.query<Organization, void>({
      query: () => `/organization/session`,
      providesTags: ['Organizations'],
    }),
    createOrganization: builder.mutation<Organization, Organization>({
      query: (organization) => ({
        url: '/organization',
        method: 'POST',
        body: organization,
      }),
      invalidatesTags: ['Organizations'],
    }),
    updateOrganization: builder.mutation<Organization, Organization>({
      query: (organization) => ({
        url: `/organization`,
        method: 'PUT',
        body: organization,
      }),
      invalidatesTags: ['Organizations'],
    }),
    getAllOrganizationAccounts: builder.query<Account[], void>({
      query: () => `/organization/account`,
      providesTags: ['OrganizationMembers'],
    }),
    inviteMembersToOrganization: builder.mutation<void, InviteMemberRequest>({
      query: (req) => ({
        url: `/organization/invite`,
        method: 'POST',
        body: req,
      }),
      invalidatesTags: ['Organizations', 'OrganizationMembers'],
    }),
    addInviteKeysToOrganization: builder.mutation<void, AddInviteKeys>({
      query: (req) => ({
        url: `/organization/invite-keys`,
        method: 'POST',
        body: req,
      }),
      invalidatesTags: ['Organizations', 'OrganizationMembers'],
    }),
    getAllOrganizationAccountsAdmin: builder.query<Account[], string>({
      query: (id) => `/organization/${id}/account`,
      providesTags: ['OrganizationMembers'],
    }),
    inviteMembersToOrganizationAdmin: builder.mutation<
      void,
      AdminInviteMemberRequest
    >({
      query: (req) => ({
        url: `/organization/${req.organizationID}/invite`,
        method: 'POST',
        body: req,
      }),
      invalidatesTags: ['Organizations', 'OrganizationMembers'],
    }),
    addInviteKeysToOrganizationAdmin: builder.mutation<
      void,
      AdminAddInviteKeys
    >({
      query: (req) => ({
        url: `/organization/${req.organizationID}/invite-keys`,
        method: 'POST',
        body: req,
      }),
      invalidatesTags: ['Organizations', 'OrganizationMembers'],
    }),
    getAllOrganizationType: builder.query<OrganizationType[], void>({
      query: () => `/organization/type`,
    }),
    getOrganizationInfo: builder.query<OrganizationInfo, string>({
      query: (organizationID: string) => `/organization/info/${organizationID}`,
    }),
  }),
});

export const {
  useGetAllOrganizationQuery,
  useGetSessionOrganizationQuery,
  useGetOrganizationQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useGetAllOrganizationAccountsQuery,
  useGetAllOrganizationAccountsAdminQuery,
  useInviteMembersToOrganizationMutation,
  useInviteMembersToOrganizationAdminMutation,
  useAddInviteKeysToOrganizationMutation,
  useAddInviteKeysToOrganizationAdminMutation,
  useGetAllOrganizationTypeQuery,
  useGetOrganizationInfoQuery,
} = organizationApi;

export default organizationApi;
