var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Icon } from "../Icon/Icon";
/**
 * SupportingText provides stylised helper text for form components.
 */
export const SupportingText = (_a) => {
    var { className, text } = _a, props = __rest(_a, ["className", "text"]);
    return (_jsxs(StyledContainer, Object.assign({ className: className }, props, { children: [_jsx(Icon, { icon: 'exclamation-circle', fontSize: 12 }), _jsx(StyledSupportingText, { children: text })] })));
};
const StyledContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.color.neutral[80]};
`;
const StyledSupportingText = styled.span `
  // default styles
  font: ${(props) => props.theme.typography.body.xs.regular};
`;
SupportingText.componentName = 'SupportingText';
export default SupportingText;
