import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { Logo } from "../../atoms";
import AccountInfo from "../AccountInfo/AccountInfo";
/**
 * NavBar displays the navigation bar.
 */
export const NavBar = ({ role, name, fallback, toHome = '/', toAccountSettings, onSignOut, isMobile, }) => {
    return (_jsxs(NavBarContainer, { children: [_jsx(NavBarLeft, { children: _jsx(HomeLink, Object.assign({ to: toHome }, { children: _jsx(Logo, { size: 'md' }) })) }), _jsx(NavBarRight, { children: _jsx(AccountInfo, { fallback: fallback, role: role, name: name, onSignOut: onSignOut, toAccountSettings: toAccountSettings }) })] }));
};
const HomeLink = styled(Link) `
  display: block;
  cursor: pointer;
  padding: ${(props) => props.theme.spacing[4]} 0;
`;
const NavBarContainer = styled.div `
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.color.illustration.eggBlue};
  background: ${(props) => props.theme.color.neutral[10]};
  padding: ${(props) => props.theme.spacing[12]}
    ${(props) => props.theme.spacing[16]};

  ${(props) => props.theme.breakpoint.sm(css `
      padding: ${props.theme.spacing[16]} ${props.theme.spacing[36]};
    `)};
`;
const NavBarLeft = styled.div ``;
const NavBarRight = styled.div ``;
NavBar.displayName = 'NavBar';
export default NavBar;
