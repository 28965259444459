import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
// theme
import theme from "../../../theme";
// hooks
import { useWindowSize } from 'hooks';
/**
 * HubSpotChat shows the HubSpot chat widget.
 */
export const HubSpotChat = ({ email, token, isVisible, trackingUrl, isApple, }) => {
    // state
    const [isHubSpotOpen, setIsHubSpotOpen] = useState(false);
    // hooks
    const windowSize = useWindowSize();
    // memo
    const isMobile = useMemo(() => {
        return windowSize[0] <= theme.breakpoint.smValue;
    }, [windowSize]);
    // methods
    const loadHubSpot = useCallback(() => {
        const scriptElement = document.createElement('script');
        scriptElement.src = trackingUrl !== null && trackingUrl !== void 0 ? trackingUrl : '';
        scriptElement.type = 'text/javascript';
        scriptElement.async = true;
        scriptElement.addEventListener('load', () => {
            window.hsConversationsSettings = Object.assign(Object.assign({}, window.hsConversationsSettings), { identificationEmail: email, identificationToken: token });
        });
        scriptElement.addEventListener('error', () => {
            console.error('Error loading HubSpot script');
        });
        document.body.appendChild(scriptElement);
        return scriptElement;
    }, []);
    // effects
    useEffect(() => {
        const scriptElement = loadHubSpot();
        const timeoutId = setTimeout(() => {
            var _a;
            (_a = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _a === void 0 ? void 0 : _a.on('widgetClosed', () => {
                setIsHubSpotOpen(false);
            });
        }, 1000);
        return () => {
            clearTimeout(timeoutId);
            scriptElement.remove();
        };
    }, []);
    useEffect(() => {
        var _a;
        const widget = (_a = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _a === void 0 ? void 0 : _a.widget;
        isHubSpotOpen ? widget === null || widget === void 0 ? void 0 : widget.open() : widget === null || widget === void 0 ? void 0 : widget.close();
    }, [isHubSpotOpen]);
    const Global = createGlobalStyle `
    div#hubspot-messages-iframe-container {
      ${(!isVisible || !isHubSpotOpen) &&
        css `
          display: none !important;
        `}
    }

    #hubspot-messages-iframe-container {
      opacity: 0;
      ${isMobile &&
        !isHubSpotOpen &&
        css `
          max-height: calc(100% - 70px) !important;
          margin-bottom: 70px !important;
        `}
      ${(!isVisible || !isHubSpotOpen) &&
        css `
          opacity: 0;
          pointer-events: none;
          touch-action: none;
        `}
      ${isHubSpotOpen &&
        css `
          opacity: 1;
          pointer-events: all;
          touch-action: auto;
        `}
    }
  `;
    return (_jsxs(_Fragment, { children: [_jsx(Global, {}), _jsx(HubSpotLauncher, Object.assign({ "$isVisible": isVisible && !isHubSpotOpen, "$isMobile": isMobile, "$isApple": isApple, onClick: () => setIsHubSpotOpen(true) }, { children: _jsx("img", { src: '/images/hubspot-icon.svg', alt: 'HubSpot' }) }))] }));
};
const HubSpotLauncher = styled.div `
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.color.primary[30]};
  color: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[4]};
  border-radius: 99999px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition:
    transform 0.1s ease-in-out,
    opacity 0.2s ease-in-out;
  z-index: 100000;
  &:hover {
    transform: scale(1.1);
  }

  ${(props) => !props.$isVisible &&
    css `
      pointer-events: none;
      opacity: 0;
    `}

  ${(props) => props.$isMobile &&
    css `
      bottom: 86px;
    `}

  ${(props) => props.$isApple &&
    css `
      bottom: 107px;
    `}
`;
HubSpotChat.displayName = 'HubSpotChat';
export default HubSpotChat;
