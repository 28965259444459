// react
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// components
import {
  Authenticate,
  InviteKeyRegistration as InviteKeyRegistrationPage,
  Toast,
} from '@karehero/llama';

// store
import { useGetOrganizationInfoQuery } from 'store/api/organization';

// store
import { useRegisterMemberByInviteKeyMutation } from 'store/api/iam';

/**
 * InviteKeyRegistration allows registering with an invite key.
 */
const InviteKeyRegistration = () => {
  // states
  const [isToastOpen, setIsToastOpen] = useState(false);

  // hooks
  const [registerMemberByInviteKey] = useRegisterMemberByInviteKeyMutation();
  const { organizationID } = useParams();
  const navigate = useNavigate();

  const {
    data: organization,
    isLoading,
    isError,
  } = useGetOrganizationInfoQuery(organizationID || '');

  useEffect(() => {
    if (isError) navigate('/sign-in');
  }, [isError, navigate]);

  const handleSubmit = useCallback(
    async (organizationID: string, inviteKey: string) => {
      if (inviteKey.trim() === '') {
        setIsToastOpen(true);
        return;
      }

      const res = await registerMemberByInviteKey({
        organizationID,
        inviteKey,
      });

      if (!('data' in res)) return;

      // @ts-ignore
      window.location.href = res?.data?.message;
    },
    [registerMemberByInviteKey],
  );

  return isLoading ? (
    <Authenticate />
  ) : (
    <>
      <Toast
        title='Invalid Input'
        description='Please enter a valid invite key'
        variant='error'
        isOpen={isToastOpen}
        setIsOpen={setIsToastOpen}
        duration={3000}
      />
      {organization && (
        <InviteKeyRegistrationPage
          organization={organization}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default InviteKeyRegistration;
