import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// components
import { ArticleCard, Button } from "../../atoms";
import { CardContainer } from "../../templates";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import theme from "../../../theme";
/**
 * SuggestedArticles displays a list of suggested articles.
 */
export const SuggestedArticles = ({ articles, bookmarkedArticles, onBookmark, isLoading = true, }) => {
    // hooks
    const [width] = useWindowSize();
    // memos
    const maxColumns = useMemo(() => {
        if (width > theme.breakpoint.xlValue)
            return 4;
        if (width > theme.breakpoint.mdValue)
            return 2;
        return 1;
    }, [width]);
    return (_jsxs(Wrapper, { children: [_jsxs(Header, { children: [_jsx(Title, { children: "Suggested Reads" }), _jsx(Button, Object.assign({ ariaLabel: 'See all', to: '/library', variant: 'secondary', size: 'sm' }, { children: "See all" }))] }), _jsx(CardContainer, Object.assign({ maxColumns: maxColumns }, { children: (articles || [{}, {}, {}, {}]).map((article, i) => (_jsx(ArticleCard, { article: article, isBookmarked: !!(bookmarkedArticles === null || bookmarkedArticles === void 0 ? void 0 : bookmarkedArticles.find((a) => a._id === article._id)), onBookmark: onBookmark, isLoading: isLoading }, i))) }))] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[20]};
`;
const Header = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
SuggestedArticles.displayName = 'SuggestedArticles';
export default SuggestedArticles;
