// react
import { useMemo, useState } from 'react';

// components
import {
  Button,
  Dialog,
  OrganizationPreview,
  TablePage,
} from '@karehero/llama';

// store
import { useGetAllOrganizationQuery } from 'store/api/organization';
import { Organization, Tier } from '@karehero/models';

// tanstack
import { createColumnHelper } from '@tanstack/react-table';

// columns
const columnHelper = createColumnHelper<Organization>();

/**
 * OrganizationTable is the dashboard page.
 */
const OrganizationTable = () => {
  // state
  const [isOrganizationPreviewOpen, setIsOrganizationPreviewOpen] =
    useState(false);
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);

  // hooks
  const { data: organizations } = useGetAllOrganizationQuery();

  // memo
  const rows = useMemo(() => {
    if (!organizations) return [];
    return organizations.map((organization) => ({
      ...organization,
    }));
  }, [organizations]);

  const tiers = useMemo(() => {
    if (!organizations) return [];
    const t: { [_: string]: Tier } = {};
    organizations.forEach((organization) => {
      organization.tierSeats?.forEach(
        (tierSeat) => (t[tierSeat.tier.name] = tierSeat.tier),
      );
    });
    return Object.keys(t).sort();
  }, [organizations]);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'Name',
      }),
      ...tiers.map((tierName) => {
        return columnHelper.accessor('tierSeats', {
          header: `${tierName} Limit`,
          cell: (info) => {
            const row = info.cell.row.original.tierSeats || [];
            return (
              row.find((tierSeat) => tierSeat.tier.name === tierName)
                ?.seatLimit || ''
            );
          },
        });
      }),
      columnHelper.accessor('organizationType', {
        header: 'Type',
        cell: (info) => info.cell.row.original.organizationType?.name || '',
      }),
    ];
  }, [tiers]);

  const actions = useMemo(() => {
    return [
      {
        label: 'View',
        onPress: (organization: Organization) => {
          setSelectedOrganization(organization);
          setIsOrganizationPreviewOpen(true);
        },
      },
    ];
  }, []);

  return (
    <>
      <TablePage
        title='Organizations'
        columns={columns}
        rows={rows}
        actions={actions}
        toolbarActions={
          <Button
            ariaLabel='new organization'
            size='sm'
            to={'/admin/organization/new'}
            iconLeft={'plus'}
            isRectangle
          >
            New Organization
          </Button>
        }
      />
      <Dialog
        variant='sheet'
        isOpen={isOrganizationPreviewOpen}
        setIsOpen={setIsOrganizationPreviewOpen}
      >
        <OrganizationPreview
          name={selectedOrganization?.name || ''}
          seatLimits={selectedOrganization?.tierSeats || []}
          toViewMembers={`/admin/organization/${selectedOrganization?.id}/member`}
          toViewTiers={`/admin/organization/${selectedOrganization?.id}/tiers`}
          toEditOrganization={`/admin/organization/${selectedOrganization?.id}/edit`}
          onClose={() => setIsOrganizationPreviewOpen(false)}
        />
      </Dialog>
    </>
  );
};

export default OrganizationTable;
