import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Card, Container, Icon, Title } from "../../atoms";
import { FloatingActions } from "../../molecules";
/**
 * PoaSuccess renders the success screen when a user has successfully
 * applied for POA.
 */
export const PoaSuccess = ({ className }) => {
    return (_jsxs(Container, Object.assign({ className: className }, { children: [_jsxs(Wrapper, { children: [_jsx(Title, { children: "Power of Attorney" }), _jsx(IconWrapper, { children: _jsx(Icon, { icon: 'check', fontSize: 24 }) }), _jsx(SubTitle, { children: "Payment Successful" }), _jsx(Description, { children: "You have successfully purchased your Power of Attorney through Zenco!" }), _jsxs(Card, Object.assign({ color: 'blue', elevation: 0, isBordered: false, isPaddingSm: true }, { children: [_jsx(CardTitle, { children: "Your next steps" }), _jsxs(CardUL, { children: [_jsx("li", { children: "Click below to return to our app\u2019s Home Screen" }), _jsxs("li", { children: ["Need help? Contact us at", ' ', _jsx("a", Object.assign({ href: 'mailto:support@karehero.com' }, { children: "support@karehero.com" }))] })] })] }))] }), _jsx(FloatingActions, { actions: [
                    {
                        label: 'Return Home',
                        to: '/',
                    },
                ] })] })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
`;
const IconWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99999px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  background: ${(props) => props.theme.color.success[40]};
  color: ${(props) => props.theme.color.neutral[10]};
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const CardTitle = styled.div `
  font: ${(props) => props.theme.typography.body.lg.medium};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const CardUL = styled.ul `
  list-style-type: disc;
  padding-left: ${(props) => props.theme.spacing[16]};

  & a {
    color: ${(props) => props.theme.color.primary[30]};
  }

  & > li {
    padding-bottom: ${(props) => props.theme.spacing[16]};
  }

  & > li:last-child {
    padding-bottom: 0;
  }
`;
PoaSuccess.displayName = 'PoaSuccess';
export default PoaSuccess;
