import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Button, ProgressBar } from "../../atoms";
import { ActionPlanAccordion } from "../../organisms";
/**
 * ActionPlan shows the list of action plan items.
 */
export const ActionPlan = ({ actionPlanItems, categories, onDismiss, }) => {
    return (_jsxs(_Fragment, { children: [_jsxs(Header, { children: [_jsxs(HeaderTop, { children: [_jsx(Title, { children: "Your personalised plan" }), _jsx(Button, Object.assign({ ariaLabel: 'all actions', variant: 'link', to: '/action-plan/all', isDarkMode: true }, { children: "All actions" }))] }), _jsx(ProgressWrapper, { children: _jsx(ProgressBar, { label: 'Your progress', limit: actionPlanItems.length, progress: actionPlanItems.filter((item) => item.isComplete).length, isPlantIconVisible: true, isDarkMode: true, isAnimate: false }) })] }), _jsx(Body, { children: _jsx(ActionPlanAccordion, { actionPlanItems: actionPlanItems, categories: categories, onDismiss: onDismiss }) })] }));
};
const Header = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[24]};
  background: linear-gradient(180deg, #96466f 0%, #713654 96.88%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
const HeaderTop = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ProgressWrapper = styled.div `
  display: flex;
  padding: ${(props) => props.theme.spacing[16]};
  background: #a24e79;
  border-radius: 8px;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.neutral[10]};
`;
const Body = styled.div `
  padding: ${(props) => props.theme.spacing[24]};
  padding-top: 0;
`;
ActionPlan.displayName = 'ActionPlan';
export default ActionPlan;
