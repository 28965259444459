// react
import { useMemo } from 'react';

// components
import { RecipientProfileCarePlan } from '@karehero/llama';

// hooks
import { useCareCircle, useCareProfile } from 'hooks';

/**
 * CarePlan shows the CarePlanPage component.
 */
const CarePlan = () => {
  // hooks
  const { currentCareCircle } = useCareCircle();
  const { numTotal, numCompleted } = useCareProfile();

  // memos
  const toCareProfile = useMemo(
    () =>
      numCompleted >= numTotal
        ? `/care-profile/general-information`
        : `/care-profile/start`,
    [numCompleted, numTotal],
  );

  return (
    <RecipientProfileCarePlan
      firstName={currentCareCircle?.careRecipientAccount?.firstName}
      limit={numTotal}
      progress={numCompleted}
      toCareProfile={toCareProfile}
      toBookCall='/book-call'
    />
  );
};

export default CarePlan;
