import { jsx as _jsx } from "react/jsx-runtime";
// lottie
import Lottie from 'lottie-react';
// assets
// @transform-path ../../../../../../src/assets/animation/success.json
import successAnimation from "../../../../../../src/assets/animation/success.json";
/**
 * LottieAnimation displays a Lottie animation.
 */
export const LottieAnimation = ({ animationData = successAnimation, loop = true, autoplay = true, }) => {
    return (_jsx(Lottie, { autoplay: autoplay, loop: loop, animationData: animationData }));
};
LottieAnimation.displayName = 'LottieAnimation';
export default LottieAnimation;
