import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { css } from 'styled-components';
import { Icon } from "../Icon/Icon";
export const Chip = ({ label, value, isSelected = false, onPress, size = 'md', isDisabled = false, }) => {
    return (_jsxs(ChipWrapper, Object.assign({ onClick: (e) => {
            if (isDisabled)
                return;
            e.stopPropagation();
            onPress === null || onPress === void 0 ? void 0 : onPress(value);
        }, "$isSelected": isSelected, "$size": size, "$isDisabled": isDisabled }, { children: [label, isSelected && _jsx(Icon, { icon: 'xmark', fontSize: size === 'sm' ? 12 : 16 })] })));
};
const ChipWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  border-radius: 99999px;
  background: ${(props) => props.theme.color.neutral[10]};
  color: ${(props) => props.theme.color.neutral[90]};
  border: 1px solid ${(props) => props.theme.color.neutral[60]};
  font: ${(props) => props.theme.typography.body.sm.regular};
  user-select: none;
  white-space: nowrap;

  cursor: pointer;
  transition-duration: 200ms;
  &:hover {
    background: ${(props) => props.theme.color.neutral[30]};
    border: 1px solid ${(props) => props.theme.color.neutral[30]};
  }

  ${(props) => props.$isSelected &&
    css `
      background: ${props.theme.color.neutral[30]};
      border: 1px solid ${props.theme.color.neutral[30]};
    `}

  ${(props) => {
    switch (props.$size) {
        case 'sm':
            return css `
          padding: ${props.theme.spacing[4]} ${props.theme.spacing[16]};
        `;
        case 'md':
            return css `
          padding: ${props.theme.spacing[12]} ${props.theme.spacing[16]};
        `;
    }
}}

  ${(props) => props.$isDisabled &&
    css `
      cursor: default;
      color: ${props.theme.color.neutral[60]};
      background: ${props.theme.color.neutral[30]};
      border: 1px solid ${props.theme.color.neutral[50]};
      &:hover {
        color: ${props.theme.color.neutral[60]};
        background: ${props.theme.color.neutral[30]};
        border: 1px solid ${props.theme.color.neutral[50]};
      }
    `}
`;
Chip.displayName = 'Chip';
export default Chip;
