var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useRef } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
// react-aria
import { mergeProps, useButton, useFocusRing } from 'react-aria';
// icons
import { Icon } from "../Icon";
// utils
import { getColorContrast } from "../../../utils/color";
/**
 * ButtonSocial allows the user to trigger an action or event, such as submitting a
 * form, opening a dialog, canceling an action, or performing a delete
 * operation.
 */
export const ButtonSocial = (_a) => {
    var { className, isDisabled = false, isInline = false, isFullWidth = false, type = 'button', icon, color = '#1877f2', ariaLabel, children, to } = _a, props = __rest(_a, ["className", "isDisabled", "isInline", "isFullWidth", "type", "icon", "color", "ariaLabel", "children", "to"]);
    // hooks
    const ref = useRef(null);
    const { isFocusVisible, focusProps } = useFocusRing();
    const { buttonProps, isPressed } = useButton(Object.assign(Object.assign({}, props), { isDisabled, type }), ref);
    return (_jsxs(StyledButtonSocial, Object.assign({}, mergeProps(buttonProps, focusProps), { to: to }, { className: className, ref: ref, "aria-label": ariaLabel, "$isFocusVisible": isFocusVisible, "$isPressed": isPressed, "$isInline": isInline, "$isFullWidth": isFullWidth, "$color": color, as: to ? Link : 'button' }, { children: [icon && _jsx(Icon, { icon: icon, type: 'fab', fontSize: 18 }), children && (_jsx(StyledButtonSocialLabel, { children: children }))] })));
};
const StyledButtonSocial = styled.button `
  display: ${(props) => (props.$isInline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing[12]};

  border: none;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;
  outline: none;

  font: ${(props) => props.theme.typography.button.md};
  text-decoration: none;

  background: ${(props) => props.$color};
  color: ${(props) => getColorContrast(props.$color || '#ffffff')};

  padding: ${(props) => props.theme.spacing[12]}
    ${(props) => props.theme.spacing[16]};

  border-radius: 99999px;

  ${(props) => props.$isFullWidth &&
    css `
      width: 100%;
    `}

  &:disabled {
    pointer-events: none;
  }
`;
const StyledButtonSocialLabel = styled.div `
  white-space: nowrap;
`;
ButtonSocial.componentName = 'ButtonSocial';
export default ButtonSocial;
