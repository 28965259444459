// react
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// hooks
import { useCareProfile, usePermissions } from 'hooks';

// components
import {
  CareProfileForm as CareProfileFormPage,
  CareProfileRoot,
} from '@karehero/llama';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

// store
import {
  useCreateManyAnswerMutation,
  useLazyGetAllCategoryQuery,
} from 'store/api/careProfile';
import { CareProfileAnswer } from '@karehero/models';

/**
 * AttendanceAllowanceForm is the dashboard page.
 */
const AttendanceAllowanceForm = () => {
  // hooks
  const { track } = useMixpanel();
  const { attendanceAllowance } = usePermissions();
  const [getAllCategory] = useLazyGetAllCategoryQuery();
  const {
    forms,
    numCompleted,
    numTotal,
    careCircle,
    careProfileFormId,
    answers,
    sections,
    setAnswers,
  } = useCareProfile({ categoryID: 'attendance-allowance' });
  const [createManyAnswer] = useCreateManyAnswerMutation();
  const navigate = useNavigate();

  // handlers
  const trackFormViewed = useCallback(() => {
    track(MixpanelEvent.AttendanceAllowanceFormViewed, {
      carePlanFormId: careProfileFormId,
    });
  }, [careProfileFormId, track]);

  const trackFormSaved = useCallback(() => {
    track(MixpanelEvent.AttendanceAllowanceFormSaved, {
      carePlanFormId: careProfileFormId,
    });
  }, [careProfileFormId, track]);

  const handleSave = useCallback(() => {
    const careProfileAnswers: CareProfileAnswer[] = [];
    Object.keys(answers).forEach((form) => {
      Object.keys(answers[form]).forEach((key) => {
        if (!careCircle?.careProfile?.id) {
          return;
        }
        careProfileAnswers.push({
          careProfileId: careCircle.careProfile.id,
          careProfileQuestionId: key,
          value: answers[form][key],
        });
      });
    });

    trackFormSaved();

    createManyAnswer(careProfileAnswers);

    // get next form
    let nextFormId: string | null = null;
    let isNext = false;
    forms?.forEach((form) => {
      if (isNext) {
        nextFormId = form.id;
        isNext = false;
        return;
      }
      if (form.id === careProfileFormId) {
        isNext = true;
        return;
      }
    });
    if (nextFormId === null) {
      navigate(`/attendance-allowance/complete`);
      return;
    }
    navigate(`/attendance-allowance/${nextFormId}`);
  }, [
    navigate,
    answers,
    careCircle,
    trackFormSaved,
    createManyAnswer,
    forms,
    careProfileFormId,
  ]);

  const toPrevious = useMemo(() => {
    // get previous form
    let prevFormId: string | null = null;
    for (let i = 0; i < (forms?.length || 0); i++) {
      const form = forms?.[i];
      if (form?.id === careProfileFormId) {
        if (i === 0) {
          continue;
        }
        prevFormId = forms?.[i - 1]?.id || null;
        continue;
      }
    }
    return prevFormId === null
      ? '/care-profile?tab=3'
      : `/attendance-allowance/${prevFormId}`;
  }, [forms, careProfileFormId]);

  // effects
  useEffect(() => {
    getAllCategory();
  }, [getAllCategory]);

  useEffect(trackFormViewed, [trackFormViewed]);

  return (
    <CareProfileRoot
      nextLabel='Save and Continue'
      limit={numTotal}
      progress={numCompleted}
      sections={sections}
      name={careCircle?.careRecipientAccount?.firstName}
      title={`${
        careCircle?.careRecipientAccount?.firstName || 'Your Loved One'
      }'s Attendance Allowance`}
      toExit='/care-profile?tab=3'
      onNext={handleSave}
      toPrevious={toPrevious}
    >
      <CareProfileFormPage
        form={forms?.find((form) => form.id === careProfileFormId)}
        value={answers}
        onChange={setAnswers}
        careRecipientName={careCircle?.careRecipientAccount?.firstName}
        isDisabled={attendanceAllowance.isModify}
      />
    </CareProfileRoot>
  );
};

export default AttendanceAllowanceForm;
