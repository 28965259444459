import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import React from 'react';
import styled from 'styled-components';
// radix
import * as RadixAccordion from '@radix-ui/react-accordion';
// components
import { Icon } from "../Icon";
/**
 * Accordion is a collapsible accordion.
 */
export const Accordion = ({ items = [], defaultValue }) => {
    return (_jsx(AccordionRoot, Object.assign({ defaultValue: defaultValue ? [defaultValue] : undefined, type: 'multiple' }, { children: items.map((item, i) => (_jsxs(React.Fragment, { children: [_jsxs(AccordionItem, Object.assign({ value: item.id }, { children: [_jsxs(AccordionHeader, { children: [item.icon && (_jsx(StyledIconContainer, Object.assign({ "$primaryColor": item.primaryColor, "$secondaryColor": item.secondaryColor }, { children: _jsx(Icon, { icon: item.icon, fontSize: 14 }) }))), _jsxs(AccordionTrigger, Object.assign({ icon: item.icon }, { children: [_jsx(AccordionTitle, { children: item.label }), _jsx(AccordionChevron, Object.assign({ className: 'chevron' }, { children: _jsx(Icon, { icon: 'chevron-down', fontSize: 16 }) }))] }))] }), _jsx(AccordionContent, { children: _jsx(AccordionContentText, { children: item.content }) })] }), item.label), i < items.length - 1 && _jsx(Divider, {})] }, item.label))) })));
};
const AccordionRoot = styled(RadixAccordion.Root) ``;
const AccordionItem = styled(RadixAccordion.Item) `
  margin-top: 1px;
  &:focus-within {
    position: relative;
    z-index: 1;
  }
`;
const AccordionHeader = styled(RadixAccordion.Header) `
  display: flex;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing[12]};
`;
const AccordionChevron = styled.div `
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.neutral[90]};
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
`;
const AccordionTrigger = styled(RadixAccordion.Trigger) `
  all: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: ${(props) => props.theme.typography.body.xl.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
  flex: 1;
  cursor: pointer;
  padding: 0 ${(props) => props.theme.spacing[4]};
  margin-left: ${(props) => (props.icon ? props.theme.spacing[12] : '0')};

  &[data-state='open'] > .chevron {
    transform: rotate(180deg);
  }
`;
const AccordionTitle = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
`;
const AccordionContent = styled(RadixAccordion.Content) `
  overflow: hidden;
  &[data-state='open'] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-state='closed'] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
`;
const AccordionContentText = styled.div `
  padding: ${(props) => props.theme.spacing[12]};
`;
const Divider = styled.div `
  border-bottom: 1px solid ${(props) => props.theme.color.neutral[50]};
  margin-bottom: ${(props) => props.theme.spacing[12]};
  padding-top: ${(props) => props.theme.spacing[12]};
  width: 100%;
`;
const StyledIconContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[4]};
  border-radius: 99999px;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  background-color: ${(props) => props.$secondaryColor || props.theme.color.neutral[40]};
  color: ${(props) => props.$primaryColor || props.theme.color.neutral[10]};
`;
Accordion.displayName = 'Accordion';
export default Accordion;
