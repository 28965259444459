import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect } from 'react';
import styled from 'styled-components';
// components
import { Title } from "../../atoms";
/**
 * Calendly is a component that displays a Hubspot contact form.
 */
export const Calendly = () => {
    useEffect(() => {
        const calendlyScript = document.createElement('script');
        calendlyScript.src =
            'https://assets.calendly.com/assets/external/widget.js';
        calendlyScript.type = 'text/javascript';
        calendlyScript.async = true;
        document.body.appendChild(calendlyScript);
    }, []);
    return (_jsxs(Wrapper, { children: [_jsx(StyledTitle, { children: "Book a call" }), _jsx(StyledCalendly, { className: 'calendly-inline-widget', "data-url": 'https://calendly.com/care-concierge-kh/care-concierge-initial-call?background_color=ffffff&text_color=4d4d4d&primary_color=984670' })] }));
};
const Wrapper = styled.div `
  height: 100%;
`;
const StyledTitle = styled(Title) `
  margin-bottom: 0;
`;
const StyledCalendly = styled.div `
  min-width: 320px;
  height: 700px;
  @media (max-width: 1460px) {
    height: 1750px;
  }
`;
Calendly.displayName = 'Calendly';
export default Calendly;
