// react
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// components
import { Authenticate as AuthenticatePage } from '@karehero/llama';

// store
import { useSignInKindeMutation } from 'store/api/iam';
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';
import { Account } from '@karehero/models';

// hooks
import { MixpanelEvent, useApp, useMixpanel } from 'hooks';

// launch darkly
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { createLdContext } from 'launchDarkly';

enum Status {
  Pending = 'pending',
  Fail = 'fail',
  Success = 'success',
}

/**
 * Authenticate is a component that renders the authentication page.
 */
const Authenticate = () => {
  // state
  const [isRequested, setIsRequested] = useState(false);
  const [status, setStatus] = useState(Status.Pending);

  // hooks
  const account = useSelector(getAccount);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [signInKinde] = useSignInKindeMutation();
  const ldClient = useLDClient();
  const flags = useFlags();
  const { track } = useMixpanel();
  const { isApp, isAndroid, isApple } = useApp();

  const handleAnalyticsIdentify = useCallback(
    (account: Account) => {
      const newContext = createLdContext(account);
      ldClient?.identify(newContext);
    },
    [ldClient],
  );

  // effects
  useEffect(() => {
    const code = searchParams.get('code');

    if (!code) return navigate('/');

    if (isRequested) return;
    setIsRequested((prev) => {
      if (prev) return true;
      (async () => {
        const res = await signInKinde({ code });
        if ('data' in res) {
          setStatus(Status.Success);
        } else {
          setStatus(Status.Fail);
        }
      })();
      return true;
    });
  }, [searchParams, navigate, signInKinde, isRequested, flags]);

  useEffect(() => {
    switch (status) {
      case Status.Success:
        if (!account) return;
        if (isApp && isApple) {
          track(MixpanelEvent.AuthenticatedIOS, {});
        }
        if (isApp && isAndroid) {
          track(MixpanelEvent.AuthenticatedAndroid, {});
        }

        handleAnalyticsIdentify(account);

        switch (account.accountRole.name) {
          case 'KareHero Administrator':
            return navigate('/admin');
          case 'Organization Owner':
            return navigate('/');
          case 'Member':
            return navigate('/');
          default:
            return navigate('/');
        }
      case Status.Fail:
        return navigate('/sign-in');
    }
  }, [
    status,
    account,
    navigate,
    handleAnalyticsIdentify,
    isApp,
    isApple,
    isAndroid,
    track,
  ]);

  return (
    <AuthenticatePage
      isEasterEgg={
        false && process.env.NODE_ENV === 'development' && flags.easterEgg
      }
    />
  );
};

export default Authenticate;
