// react
import { useEffect, useState } from 'react';

// launch darkly
import { useFlags } from 'launchdarkly-react-client-sdk';

// branch.io
import branch, { SessionData } from 'branch-sdk';

export const useDeepLink = () => {
  // hooks
  const flags = useFlags();

  // states
  const [deepLink, setDeepLink] = useState('');

  // methods
  useEffect(() => {
    if (!flags.deepLink) return;

    branch.init(process.env.REACT_APP_BRANCH_IO_KEY || '');

    branch.data((err: any, data: SessionData | null) => {
      if (err) {
        console.error('Branch data error:', err);
        return;
      }

      const deeplinkPath = (data?.data_parsed as { $deeplink_path?: string })
        ?.$deeplink_path;

      if (deeplinkPath) setDeepLink(deeplinkPath);
    });
  }, [flags, setDeepLink]);

  return {
    deepLink,
  };
};
