// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// store
import { useSelector } from 'store';
import { kindeClientId, kindeEnvironmentUrl } from 'store/api';
import { getAccount } from 'store/slices/iamSlice';
import { useUpdateIsOnboardedMutation } from 'store/api/iam';
import { useRegisterByInviteMutation } from 'store/api/iam';

// components
import {
  OnboardingCarousel,
  OnboardingFlow,
  OnboardingHero,
} from '@karehero/llama';

// config
import {
  onboardingCarouselConfig,
  onboardingHeroConfig,
} from 'config/onboarding';

/**
 * OnboardingInvite takes the user through the onboarding flow for a care concierge.
 */
const OnboardingInvite = () => {
  // hooks
  const account = useSelector(getAccount);
  const navigate = useNavigate();
  const [updateIsOnboarded] = useUpdateIsOnboardedMutation();
  const [searchParams] = useSearchParams();
  const [registerByInvite] = useRegisterByInviteMutation();

  // state
  const [token] = useState(searchParams.get('token'));

  // methods
  const handleRegister = useCallback(
    async (token: string) => {
      const res = await registerByInvite({
        token,
      });

      if (!('data' in res)) return false;

      return true;
    },
    [registerByInvite],
  );

  const handleComplete = useCallback(async () => {
    if (!token) return;

    await updateIsOnboarded();
    await handleRegister(token);

    const url = new URL(`https://${kindeEnvironmentUrl}/oauth2/auth`);
    const queryParams = new URLSearchParams({
      response_type: 'code',
      client_id: kindeClientId,
      redirect_uri: `${window.location.origin}/authenticate/register`,
      scope: 'openid profile email',
      prompt: 'create',
      /// random state
      state: 'i3Dd8bE1lX',
    });

    url.search = queryParams.toString();
    window.location.href = url.toString();
  }, [updateIsOnboarded, handleRegister, token]);

  // memos
  const onboardingCareConciergeConfig = useMemo(
    () => [
      {
        id: 'hero',
        Component: OnboardingHero,
        config: onboardingHeroConfig,
      },
      {
        id: 'carousel',
        Component: OnboardingCarousel,
        config: onboardingCarouselConfig,
      },
    ],
    [],
  );

  useEffect(() => {
    if (!token && (!account || account.isOnboarded)) navigate('/');
  }, [token, navigate, account]);

  return (
    <OnboardingFlow
      config={onboardingCareConciergeConfig}
      onComplete={handleComplete}
    />
  );
};

OnboardingInvite.displayName = 'OnboardingInvite';
export default OnboardingInvite;
