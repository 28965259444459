// components
import { InvitePortal as InvitePortalPage } from '@karehero/llama';

// store
import {
  useGetAllOrganizationAccountsQuery,
  useGetSessionOrganizationQuery,
  useInviteMembersToOrganizationMutation,
  useAddInviteKeysToOrganizationMutation,
} from 'store/api/organization';
import { newToast } from 'store/slices/toastSlice';

import { useDispatch } from 'react-redux';

/**
 * InvitePortal shows the accounts and invited members of an organization
 */
const InvitePortal = () => {
  // hooks
  const [inviteMembers] = useInviteMembersToOrganizationMutation();
  const [addInviteKeys] = useAddInviteKeysToOrganizationMutation();
  const dispatch = useDispatch();
  const { data: accounts } = useGetAllOrganizationAccountsQuery();
  const { data: organization } = useGetSessionOrganizationQuery();

  return (
    <InvitePortalPage
      accounts={accounts}
      organization={organization}
      inviteMembers={inviteMembers}
      addInviteKeys={addInviteKeys}
      notifyToast={(req: { title: string; description: string }) =>
        dispatch(newToast(req))
      }
    />
  );
};

export default InvitePortal;
