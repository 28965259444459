import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// components
import { Button } from "../../atoms";
/**
 * FloatingActions shows floating actions at the bottom of the screen.
 */
export const FloatingActions = ({ className, actions, isSticky, }) => {
    return (_jsx(ActionButtons, Object.assign({ className: className, "$isSticky": isSticky }, { children: actions === null || actions === void 0 ? void 0 : actions.map((action) => (_jsx(Button, Object.assign({ ariaLabel: action.label, variant: action.variant, onPress: action.onPress, iconLeft: action.iconLeft, iconRight: action.iconRight, to: action.to, isDisabled: action.isDisabled }, { children: action.label }), action.label))) })));
};
const ActionButtons = styled.div `
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[16]} 0;
  border-top: 1px solid ${(props) => props.theme.color.neutral[50]};
  background: ${(props) => props.theme.color.neutral[10]};
  z-index: 1;

  & button {
    min-width: 120px;
  }
  ${(props) => props.$isSticky &&
    css `
      position: sticky;
    `}
`;
FloatingActions.displayName = 'FloatingActions';
export default FloatingActions;
