// react
import { useCallback, useMemo } from 'react';

// store
import { kindeClientId, kindeEnvironmentUrl } from 'store/api';
import { useSignOutMutation } from 'store/api/iam';

export const useKinde = () => {
  // hooks
  const [signOutMutation] = useSignOutMutation();

  // memos
  const kindeUrl = useMemo(() => {
    const url = new URL(`https://${kindeEnvironmentUrl}/oauth2/auth`);
    const queryParams = new URLSearchParams({
      response_type: 'code',
      client_id: kindeClientId,
      redirect_uri: `${window.location.origin}/authenticate`,
      scope: 'openid profile email',
      /// random state
      state: 'i3Dd8bE1lX',
    });
    url.search = queryParams.toString();
    return url.toString();
  }, []);

  const signIn = useCallback(() => {
    window.location.href = kindeUrl;
  }, [kindeUrl]);

  const signOut = useCallback(() => {
    signOutMutation();

    let kindeLogoutUrl = new URL(`https://${kindeEnvironmentUrl}/logout`);
    const queryParams = new URLSearchParams({
      redirect: `${window.location.origin}/sign-in`,
    });
    kindeLogoutUrl.search = queryParams.toString();

    window.location.href = kindeLogoutUrl.toString();
  }, [signOutMutation]);

  return {
    signIn,
    signOut,
  };
};
