// react
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// store
import { setCurrentCareCircleId } from 'store/slices/careCircleSlice';

// components
import { SignIn as SignInPage } from '@karehero/llama';

// hooks
import { useKinde } from 'hooks';

/**
 * SignIn is the sign in page, and handles user authentication.
 */
const SignIn = () => {
  // hooks
  const dispatch = useDispatch();
  const { signIn } = useKinde();

  // effects
  useEffect(() => {
    dispatch(setCurrentCareCircleId(undefined));
  }, [dispatch]);

  return <SignInPage onSubmit={signIn} />;
};

export default SignIn;
