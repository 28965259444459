import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useState } from 'react';
import styled from 'styled-components';
// components
import { Button, Input } from "../../atoms";
/**
 * InviteKeyRegistrationForm is a component that allows registering with an invite key
 */
export const InviteKeyRegistrationForm = ({ inviteKeyLabel, onSubmit, }) => {
    const [inviteKey, setInviteKey] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(inviteKey);
    };
    return (_jsxs(StyledInviteKeyRegistrationForm, Object.assign({ onSubmit: handleSubmit }, { children: [_jsx(StyledTopSection, { children: _jsx(StyledInputFields, { children: _jsx(Input, { ariaLabel: 'invite key input field', type: 'text', label: inviteKeyLabel, isHideTitle: false, value: inviteKey, onChange: setInviteKey, placeholder: inviteKeyLabel }) }) }), _jsx(StyledButton, Object.assign({ ariaLabel: 'register', type: 'submit' }, { children: "Register" }))] })));
};
const StyledInviteKeyRegistrationForm = styled.form `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  align-items: center;
`;
const StyledInputFields = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
`;
const StyledTopSection = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
`;
const StyledButton = styled(Button) `
  width: 100%;
`;
InviteKeyRegistrationForm.displayName = 'InviteKeyRegistrationForm';
export default InviteKeyRegistrationForm;
