import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { Link as ReactLink } from 'react-router-dom';
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { Keni, Icon } from "../../atoms";
import ProfileSummary from "../ProfileSummary/ProfileSummary";
/**
 * NavVertical is a navigation drawer that slides in from the left side of the
 * screen.
 */
export const NavVertical = ({ className, imgSrc, fallback, name, role, links, currentLink, isCollapsed, onAfterNavigate, }) => {
    return (_jsxs(_Fragment, { children: [_jsxs(StyledNavVertical, Object.assign({ className: className, "$isCollapsed": isCollapsed }, { children: [_jsx(Header, { children: _jsx(ProfileSummary, { imgSrc: imgSrc, fallback: fallback, name: name, role: role, isText: !isCollapsed, mode: 'light' }) }), _jsx(Links, { children: links === null || links === void 0 ? void 0 : links.map((link) => (_jsx("div", { children: link.to && (_jsxs(StyledLink, Object.assign({ to: link.to, onClick: () => onAfterNavigate === null || onAfterNavigate === void 0 ? void 0 : onAfterNavigate(), "$isCurrent": currentLink === link.to, "$isIconOnly": isCollapsed }, { children: [_jsx(Icon, { icon: link.icon, fontSize: 16 }), !isCollapsed && link.label] }), link.label)) }, link.label))) })] })), !isCollapsed && _jsx(StyledKeni, {})] }));
};
const StyledNavVertical = styled.div `
  position: relative;
  display: inline-flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[64]};
  height: 100%;
  box-sizing: border-box;
  ${(props) => !props.$isCollapsed &&
    css `
      width: 100%;
    `}
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
const Links = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[12]};
`;
const StyledLink = styled(ReactLink) `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[12]};
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  padding: ${(props) => props.theme.spacing[12]}
    ${(props) => props.theme.spacing[16]};
  border-radius: 12px;
  min-height: 48px;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  &:hover {
    background: ${(props) => props.theme.color.custom.navDrawerBlue};
  }
  ${(props) => props.$isCurrent &&
    css `
      background: ${props.theme.color.custom.navDrawerBlue};
      color: ${props.theme.color.neutral[90]};
      pointer-events: none;
    `}

  ${(props) => props.$isIconOnly &&
    css `
      justify-content: center;
      padding: ${props.theme.spacing[12]};
    `}
`;
const StyledKeni = styled(Keni) `
  position: absolute;
  bottom: -160px;
  right: 50%;
  transform: translateX(50%);
  max-width: 120px;
  transition: bottom 0.4s ease-in-out;
  &:hover {
    bottom: -130px;
  }

  @media (max-height: 700px) {
    display: none;
  }
`;
NavVertical.displayName = 'NavVertical';
export default NavVertical;
