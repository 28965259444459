// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// store
import { dispatch } from 'store';
import { clearAuth } from 'store/slices/iamSlice';
import {
  useRegisterMemberByDomainMutation,
  useRegisterMemberByInviteMutation,
  useSetTokenMutation,
} from 'store/api/iam';
import { kindeClientId, kindeEnvironmentUrl } from 'store/api';

// components
import {
  Authenticate,
  OnboardingActionType,
  OnboardingEditor,
  OnboardingFlow,
  OnboardingKeni,
} from '@karehero/llama';
import { FieldType } from '@karehero/llama/src/components/organisms/EditorFields/fieldHelper';

// hooks
import { useKinde } from 'hooks';

// config
import {
  onboardingKeniConfirmEmailConfig,
  onboardingKeniConfirmMigrationConfig,
} from 'config/onboarding';

// validation
const validateEmail = (value?: string) => /\S+@\S+\.\S+/.test(value || '');

/**
 * OnboardingMemberRegister takes the user through the onboarding flow for a care concierge.
 */
const OnboardingMemberRegister = () => {
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState<{ value: string }>();
  const [token, setToken] = useState('');
  const [isMigration, setIsMigration] = useState(false);

  // hooks
  const [searchParams] = useSearchParams();
  const [setRemoteToken] = useSetTokenMutation();
  const [registerMemberByDomain] = useRegisterMemberByDomainMutation();
  const [registerMemberByInvite] = useRegisterMemberByInviteMutation();
  const { signIn } = useKinde();

  // methods
  const clear = useCallback(() => {
    // localStorage
    const state = localStorage.getItem('reduxState');
    const reduxState = JSON.parse(state || '{}');
    delete reduxState.iam;
    localStorage.setItem('reduxState', JSON.stringify(reduxState));

    // redux
    dispatch(clearAuth);
  }, []);

  const handleRegisterMemberByDomain =
    useCallback(async (): Promise<boolean> => {
      const res = await registerMemberByDomain({
        email: email?.value || '',
      });
      if ('error' in res) {
        if ('status' in res.error && res.error.status === 409) {
          signIn();
        }
        if ('status' in res.error && res.error.status === 410) {
          setIsMigration(true);
          return true;
        }
        return false;
      }
      return true;
    }, [registerMemberByDomain, email, signIn]);

  const handleRegisterMemberByInvite = useCallback(
    async (token: string): Promise<boolean> => {
      clear();
      const url = new URL(`https://${kindeEnvironmentUrl}/oauth2/auth`);
      const queryParams = new URLSearchParams({
        response_type: 'code',
        client_id: kindeClientId,
        redirect_uri: `${window.location.origin}/register/member`,
        scope: 'openid profile email',
        prompt: 'create',
        /// random state
        state: 'i3Dd8bE1lX',
      });

      const res = await registerMemberByInvite({
        token,
      });
      if (!('data' in res)) {
        if ('status' in res.error && res.error.status === 404) {
          signIn();
        }
        return false;
      }

      url.search = queryParams.toString();
      window.location.href = url.toString();

      return true;
    },
    [registerMemberByInvite, signIn, clear],
  );

  const handleComplete = useCallback(async () => {
    if (token) {
      const res = await handleRegisterMemberByInvite(token);
      if (!res) {
        console.error('Failed to register member by invite');
        return false;
      }
    } else {
      const res = await handleRegisterMemberByDomain();
      if (!res) {
        console.error('Failed to register member by domain');
        return false;
      }
    }
    return true;
  }, [token, handleRegisterMemberByInvite, handleRegisterMemberByDomain]);

  // effects
  useEffect(() => {
    const token = searchParams.get('token');
    if (!token) {
      setIsLoading(false);
      return;
    }
    (async () => {
      const res = await setRemoteToken(token);
      if (!('data' in res)) {
        console.error('Failed to set token');
        setIsLoading(false);
        return;
      }
      setToken(token);
      setIsLoading(false);
    })();
  }, [searchParams, setRemoteToken]);

  useEffect(() => {
    if (token) {
      handleComplete();
    }
  }, [token, handleComplete]);

  // memos
  const onboardingMemberRegisterConfig = useMemo(
    () => [
      {
        id: 'register',
        Component: OnboardingEditor,
        onBeforeNext: handleComplete,
        config: [
          {
            id: 'register-new-account',
            title: 'Register new account',
            subTitle:
              'KareHero is currently an invite-only platform. If you have not received an email invite from us, enter your work email to get started.',
            value: email,
            onChange: setEmail,
            fieldDef: {
              label: 'Work email address',
              field: {
                type: FieldType.Email,
              },
              validation: validateEmail,
            },
            actions: [
              {
                label: 'Back',
                onClick: OnboardingActionType.To,
                to: '/sign-in',
              },
              {
                label: 'Next',
                onClick: OnboardingActionType.Next,
              },
            ],
          },
        ],
      },
      {
        id: 'keni',
        Component: OnboardingKeni,
        config: isMigration
          ? onboardingKeniConfirmMigrationConfig
          : onboardingKeniConfirmEmailConfig,
      },
    ],
    [email, handleComplete, isMigration],
  );

  return !token && !isLoading ? (
    <OnboardingFlow
      config={onboardingMemberRegisterConfig}
      onComplete={() => {}}
    />
  ) : (
    <Authenticate />
  );
};

OnboardingMemberRegister.displayName = 'OnboardingMemberRegister';
export default OnboardingMemberRegister;
