// react
import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useOutlet } from 'react-router-dom';
import styled from 'styled-components';

// components
import {
  Chat,
  Container,
  DrawerTemplate,
  Link,
  NavBarMobile,
  NavDrawer,
  theme,
  Transition,
} from '@karehero/llama';

// store
import { dispatch, useSelector } from 'store';
import { isDrawerCollapsed, toggleDrawer } from 'store/slices/navigationSlice';

// hooks
import { useApp, useCareProfile, useChat, useWindowSize } from 'hooks';

// launch darkly
import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * RootMember is the main component of the application.
 */
const RootMember = () => {
  // refs
  const nodeRef = useRef(null);

  // hooks
  const { numCompleted, numTotal, careCircle } = useCareProfile();
  const currentOutlet = useOutlet();
  const windowSize = useWindowSize();
  const flags = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const isCollapsed = useSelector(isDrawerCollapsed);
  const { messages, sendMessage, isChatLoading, createChat } = useChat();
  const { isApp, isApple } = useApp();

  // memo
  const careRecipient = useMemo(() => {
    return careCircle?.careRecipientAccount;
  }, [careCircle]);

  const fallback = useMemo(() => {
    return `${careRecipient?.firstName?.[0]}${
      careRecipient?.lastName?.[0] || ''
    }`.toUpperCase();
  }, [careRecipient]);

  const name = useMemo(() => {
    return `${careRecipient?.firstName} ${careRecipient?.lastName || ''}`;
  }, [careRecipient]);

  const isMobile = useMemo(() => {
    return windowSize[0] <= theme.breakpoint.smValue;
  }, [windowSize]);

  const isHideUi = useMemo(
    () =>
      location.pathname.includes('prototype') ||
      location.pathname.includes('care-profile/') ||
      location.pathname.includes('care-plan/') ||
      location.pathname.includes('attendance-allowance/') ||
      location.pathname.includes('action-plan/') ||
      location.pathname.includes('/action-plan') ||
      location.pathname.includes('/directory') ||
      location.pathname === '/',
    [location],
  );

  // effects
  useEffect(() => {
    document
      .getElementById('drawer-template-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  const navVerticalProps = useMemo(() => {
    let links: Link[] = [
      {
        icon: 'house-blank',
        label: 'Home',
        to: `/`,
      },
      {
        icon: 'list-check',
        label: 'Actions',
        to: `/action-plan`,
      },
      {
        icon: 'hand-holding-heart',
        label: 'Care Profile',
        to: `/care-profile`,
      },
      {
        icon: 'magnifying-glass-location',
        label: 'Care Search',
        to: `/directory`,
      },
      {
        icon: 'book-open',
        label: 'Library',
        to: `/library`,
      },
    ];

    const addAfter = (to: string, newLink: Link) => {
      const index = links.findIndex((link) => link.to === to);
      links = [...links.slice(0, index), newLink, ...links.slice(index)];
    };

    if (flags.careCircle) {
      addAfter('/help', {
        icon: 'disc-drive',
        label: 'Care Circle',
        to: `/circle`,
      });
    }
    if (flags.webinars) {
      addAfter('/library', {
        icon: 'tv',
        label: 'Webinars',
        to: `/webinar`,
      });
    }

    return {
      imgSrc: careRecipient?.imgSrc,
      fallback,
      name,
      role: 'Your Loved One',
      percentageComplete: numTotal ? ((numCompleted || 0) / numTotal) * 100 : 0,
      navigate,
      isCollapsed,
      onToggleCollapse: () => dispatch(toggleDrawer()),
      currentLink: location.pathname,
      links: links,
    };
  }, [
    careRecipient,
    fallback,
    isCollapsed,
    location.pathname,
    name,
    navigate,
    numCompleted,
    numTotal,
    flags,
  ]);

  return (
    <RootMemberWrapper>
      {flags.attendanceAllowanceAiChat &&
        location.pathname.includes('/attendance-allowance/') && (
          <Chat
            messages={messages}
            onSend={sendMessage}
            onReset={async () => {
              createChat();
            }}
            isMobile={isMobile}
            isTyping={isChatLoading}
            isApple={isApp && isApple}
          />
        )}
      <DrawerTemplate
        isOverflowHidden={location.pathname.includes('prototype')}
        isNoPadding={isHideUi}
        drawer={
          !location.pathname.includes('/poa-success') &&
          !location.pathname.includes('attendance-allowance/') &&
          !location.pathname.includes('care-profile/') &&
          !location.pathname.includes('care-plan/') && (
            <NavDrawer {...navVerticalProps} />
          )
        }
        navBarMobile={
          isMobile &&
          !location.pathname.includes('/poa-success') &&
          !location.pathname.includes('attendance-allowance/') &&
          !location.pathname.includes('care-profile/') &&
          !location.pathname.includes('care-plan/') && (
            <NavBarMobile
              links={navVerticalProps.links}
              currentPath={location.pathname}
            />
          )
        }
        content={
          isHideUi ? (
            <Transition path={location.pathname} ref={nodeRef}>
              {currentOutlet}
            </Transition>
          ) : (
            <Container size='xl'>
              <Transition path={location.pathname} ref={nodeRef}>
                {currentOutlet}
              </Transition>
            </Container>
          )
        }
      />
    </RootMemberWrapper>
  );
};

const RootMemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

RootMember.displayName = 'RootMember';
export default RootMember;
