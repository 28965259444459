// theme
import { generateFont, generateWeightedFont } from './types';
export const typography = {
    heading: {
        '1xl': generateFont('Poppins', 16, 1.5, 600),
        '2xl': generateFont('Poppins', 20, 1.3, 500),
        '3xl': generateFont('Poppins', 24, 1.2, 500),
        '4xl': generateFont('Poppins', 32, 1.5, 500),
        '5xl': generateFont('Poppins', 34, 1.2, 500),
        '6xl': generateFont('Poppins', 40, 1.1, 500),
        '7xl': generateFont('Poppins', 48, 1.1, 500),
        '8xl': generateFont('Poppins', 60, 1.1, 400),
        '9xl': generateFont('Poppins', 80, 1, 500),
    },
    body: {
        xxs: generateWeightedFont('Poppins', 10, 1.5),
        xs: generateWeightedFont('Poppins', 12, 1.5),
        sm: generateWeightedFont('Poppins', 14, 1.5),
        md: generateWeightedFont('Poppins', 16, 1.5),
        lg: generateWeightedFont('Poppins', 18, 1.5),
        xl: generateWeightedFont('Poppins', 24, 1.5),
        xxl: generateWeightedFont('Poppins', 32, 1.5),
    },
    button: {
        sm: generateFont('Poppins', 12, 1.3, 500),
        md: generateFont('Poppins', 14, 1.4, 500),
        lg: generateFont('Poppins', 17, 1.4, 500),
    },
};
export default typography;
