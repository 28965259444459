import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// components
import { Editor } from "../../organisms/Editor/Editor";
import { createFieldHelper, FieldType, } from "../../organisms/EditorFields/fieldHelper";
const fields = createFieldHelper({
    addressLine1: {
        label: 'Address Line 1',
        field: {
            type: FieldType.Text,
        },
    },
    addressLine2: {
        label: 'Address Line 2',
        field: {
            type: FieldType.Text,
        },
    },
    addressLine3: {
        label: 'Address Line 3',
        field: {
            type: FieldType.Text,
        },
    },
    city: {
        label: 'City',
        field: {
            type: FieldType.Text,
        },
    },
    postalCode: {
        label: 'Postal Code',
        field: {
            type: FieldType.Text,
        },
    },
});
/**
 * AddressField allows you to add an address field to your form.
 */
export const AddressField = ({ label, value, onChange, isDisabled, }) => {
    // memo
    const contact = useMemo(() => {
        let c = {};
        try {
            c = JSON.parse(value || '{}');
        }
        catch (e) {
            console.error(e);
        }
        return c;
    }, [value]);
    return (_jsxs(Wrapper, { children: [_jsx(Title, { children: label }), _jsx(Editor, { fields: fields, value: contact, onChange: (val) => onChange(JSON.stringify(val)), isFloatingActions: false, isActionsDisabled: true, isForm: false, isDisabled: isDisabled })] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.primary[90]};
`;
AddressField.displayName = 'AddressField';
export default AddressField;
