// components
import { RecipientProfileAttendanceAllowance } from '@karehero/llama';

// hooks
import { useCareCircle, useCareProfile } from 'hooks';

/**
 * AttendanceAllowance shows the AttendanceAllowancePage component.
 */
const AttendanceAllowance = () => {
  // hooks
  const { currentCareCircle } = useCareCircle();
  const { numTotal, numCompleted } = useCareProfile({
    categoryID: 'attendance-allowance',
  });

  return (
    <RecipientProfileAttendanceAllowance
      firstName={currentCareCircle?.careRecipientAccount?.firstName}
      limit={numTotal}
      progress={numCompleted}
      toAttendanceAllowance={'/attendance-allowance/start'}
    />
  );
};

export default AttendanceAllowance;
