var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// Given a subquestion and the answer of its parent, this checks whether the subquestion is relevant
export var isSubQuestionRelevant = function (subQuestion, parentAnswer) {
    var conditionValue = subQuestion.conditionValue;
    switch (subQuestion.conditionOperator) {
        case 'equal':
            return parentAnswer === conditionValue;
        case 'not-equal':
            return parentAnswer !== conditionValue;
        case 'contains':
            return parentAnswer.includes(conditionValue);
        case 'not-contains':
            return !parentAnswer.includes(conditionValue);
        case 'greater-than':
            return conditionValue === undefined || parentAnswer > conditionValue;
        case 'less-than':
            return conditionValue === undefined || parentAnswer < conditionValue;
        case 'greater-than-or-equal':
            return conditionValue === undefined || parentAnswer >= conditionValue;
        case 'less-than-or-equal':
            return conditionValue === undefined || parentAnswer <= conditionValue;
        default:
            return true;
    }
};
export var listAllSubQuestions = function (questions) {
    var subQuestions = questions.reduce(function (acc, question) {
        return __spreadArray(__spreadArray([], acc, true), (question.careProfileSubQuestions || []), true);
    }, []);
    if (subQuestions.length === 0)
        return __spreadArray([], questions, true);
    return __spreadArray(__spreadArray([], questions, true), listAllSubQuestions(subQuestions), true);
};
export var listAllQuestionsOfSubCategory = function (subCategory) {
    var _a;
    return (_a = subCategory === null || subCategory === void 0 ? void 0 : subCategory.careProfileForms) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, form) { return __spreadArray(__spreadArray([], acc, true), listAllSubQuestions(form.careProfileQuestions), true); }, []);
};
export var questionArrayToMap = function (questions) {
    return questions.reduce(function (acc, question) {
        var _a;
        return __assign(__assign({}, acc), (_a = {}, _a[question.id] = question, _a));
    }, {});
};
