import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
// assets
// @transform-path ../../../../../../src/assets/logo.svg
import logo from "../../../../../../src/assets/logo.svg";
/**
 * Logo displays the KareHero logo.
 */
export const Logo = ({ size = 'md' }) => {
    return (_jsx(StyledLogo, Object.assign({ size: size }, { children: _jsx("img", { src: logo, alt: 'logo' }) })));
};
const StyledLogo = styled.div `
  & > img {
    object-fit: contain;
    ${(props) => {
    switch (props.size) {
        case 'sm':
            return `
            min-height: 16px;
            max-height: 16px;
          `;
        case 'md':
            return `
            min-height: 23px;
            max-height: 23px;
          `;
        case 'lg':
            return `
            min-height: 36px;
            max-height: 36px;
          `;
    }
}};
  }
`;
Logo.displayName = 'Logo';
export default Logo;
