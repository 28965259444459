import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Button, Container, Icon, Logo } from "../../atoms";
// assets
// @transform-path ../../../../../../src/assets/onboarding/conciergeTeam.jpg
import conciergeTeam from "../../../../../../src/assets/onboarding/conciergeTeam.jpg";
import { OnboardingActionType, toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
export const OnboardingActivatedStart = toGenericOnboarding(({ config, currentIndex = 0, handleClick, }) => {
    var _a, _b, _c, _d;
    return (_jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(Logo, { size: 'md' }), _jsx(ImageWrapper, { children: _jsx(Icon, { fontSize: 32, icon: 'hand-holding-heart' }) }), _jsxs(ContentContainer, { children: [_jsxs(BodyWrapper, { children: [_jsx(Title, { children: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.title }), _jsx("img", { alt: 'header', src: conciergeTeam }), _jsx(BodyTextWrapper, { children: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.text.map((text) => (_jsx(Text, { children: text }, text))) })] }), _jsx(Button, Object.assign({ size: 'lg', ariaLabel: (_c = config[currentIndex]) === null || _c === void 0 ? void 0 : _c.ctaButtonLabel, onPress: () => handleClick === null || handleClick === void 0 ? void 0 : handleClick(OnboardingActionType.Next) }, { children: (_d = config[currentIndex]) === null || _d === void 0 ? void 0 : _d.ctaButtonLabel }))] })] }) })));
});
const StyledContainer = styled(Container) `
  background: ${(props) => props.theme.color.surfaces.offWhite};
  height: unset;
  flex: 1;
`;
const BodyTextWrapper = styled.div `
  display: grid;
  gap: ${(props) => props.theme.spacing[16]};
`;
const BodyWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[32]};

  img {
    border-radius: ${(props) => props.theme.spacing[8]};
    width: 100%;
  }
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${(props) => props.theme.spacing[24]}
    ${(props) => props.theme.spacing[12]};
  gap: ${(props) => props.theme.spacing[32]};
`;
const ImageWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  background: ${(props) => props.theme.color.cards.red.secondary};
  border-radius: 99999px;
  color: ${(props) => props.theme.color.primary[80]};
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.body.xl.semibold};
  color: ${(props) => props.theme.color.primary[80]};
  text-align: center;
`;
const ContentContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  flex: 1;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 100%;
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: left;
  @media (min-width: 546px) {
    font: ${(props) => props.theme.typography.body.md.regular};
  }
`;
OnboardingActivatedStart.displayName = 'OnboardingActivatedStart';
export default toGenericOnboarding(OnboardingActivatedStart);
