import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// react
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// components
import { Button, Card, Container } from "../../atoms";
import { ActionPlanCardGroup, ProfileSummary } from "../../molecules";
import { SuggestedArticles, } from "../../organisms";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import { theme } from "../../../theme";
// assets
// @transform-path ../../../../../../src/assets/dashboard/header.png
import headerImage from "../../../../../../src/assets/dashboard/header.png";
/**
 * Dashboard is the page for the care profile.
 */
export const Dashboard = ({ suggestedArticles, actionPlanItems, onActionPlanItemDismiss, account, }) => {
    // hooks
    const [width] = useWindowSize();
    return (_jsxs(Wrapper, { children: [_jsxs(HeaderWrapper, { children: [_jsxs(HeaderTitle, { children: ["Welcome, ", `${account === null || account === void 0 ? void 0 : account.firstName}`] }), _jsx(CardLink, Object.assign({ to: '/book-call' }, { children: _jsx(Card, Object.assign({ elevation: 2, isPaddingSm: true }, { children: _jsxs(DashboardHeader, { children: [_jsx(ProfileSummary, { fallback: '', name: 'Ask any question', role: 'Your Care Expert', size: 'sm', icon: 'phone' }), _jsx(StyledButton, Object.assign({ ariaLabel: 'book a call', size: 'sm' }, { children: "Book a call" }))] }) })) })), _jsx(HeaderBackground, { children: _jsx("img", { src: headerImage, alt: 'header image' }) })] }), _jsx(Container, { children: _jsxs(Body, { children: [_jsxs(Sub, { children: [_jsxs(SubHeader, { children: [_jsx(CardTitle, { children: "Your Next Actions" }), _jsx(StyledLinkButton, Object.assign({ ariaLabel: 'see plan', to: '/action-plan', variant: 'secondary', size: 'sm' }, { children: "See plan" }))] }), (actionPlanItems === null || actionPlanItems === void 0 ? void 0 : actionPlanItems.length) > 0 ? (_jsx(ActionPlanCardGroup, { actionPlanItems: actionPlanItems.slice(0, width > theme.breakpoint.lgValue ? 4 : 3), onDismiss: onActionPlanItemDismiss })) : (_jsx(NoData, { children: "You're all caught up! \uD83C\uDF89" }))] }), _jsx(SuggestedArticles, Object.assign({}, suggestedArticles))] }) })] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[48]};
  padding-bottom: ${(props) => props.theme.spacing[48]};
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[48]};
`;
const CardTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const NoData = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const HeaderWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
  position: relative;
  padding: ${(props) => props.theme.spacing[24]};
`;
const CardLink = styled(Link) `
  all: unset;
  cursor: pointer;
  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.02);
  }
`;
const DashboardHeader = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
`;
const HeaderTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.surfaces.white};
  gap: ${(props) => props.theme.spacing[24]};
`;
const HeaderBackground = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      ${(props) => props.theme.color.neutral[100]},
      transparent
    );
    opacity: 0.5;
  }
`;
const Sub = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
`;
const SubHeader = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const StyledLinkButton = styled(Button) `
  text-decoration: none;
`;
const StyledButton = styled(Button) `
  pointer-events: none;
`;
Dashboard.displayName = 'Dashboard';
export default Dashboard;
