// react
import { useEffect } from 'react';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

// components
import { Calendly as BookCallPage } from '@karehero/llama';

/**
 * BookCall shows the BookCallPage component.
 */
const BookCall = () => {
  // hooks
  const { track } = useMixpanel();

  // effects
  useEffect(() => {
    track(MixpanelEvent.BookCallViewed, {});
  }, [track]);

  return <BookCallPage />;
};

export default BookCall;
