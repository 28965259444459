import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo, useState } from 'react';
// components
import { Button, Dialog } from "../../atoms";
import { Editor, FieldType, createFieldHelper } from "../../organisms";
import { TablePage } from '../TablePage/TablePage';
// tanstack
import { createColumnHelper } from '@tanstack/react-table';
// columns
const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor('tier.name', {
        header: 'Name',
    }),
    columnHelper.accessor('seatLimit', {
        header: `Limit`,
        cell: (info) => info.cell.getValue() || 0,
    }),
    columnHelper.accessor('seatsAvailable', {
        header: `Available`,
        cell: (info) => info.cell.getValue() || 0,
    }),
    columnHelper.accessor('seatsUsed', {
        header: `Used`,
        cell: (info) => info.cell.getValue() || 0,
    }),
    columnHelper.accessor('seatsInvited', {
        header: `Invited`,
        cell: (info) => info.cell.getValue() || 0,
    }),
    columnHelper.accessor('seatsRegistered', {
        header: `Registered`,
        cell: (info) => info.cell.getValue() || 0,
    }),
];
/**
 * OrganizationTierTable is the dashboard page.
 */
export const OrganizationTierTable = ({ isEditable = true, organization, tiers, updateOrganization, }) => {
    // state
    const [selectedTierSeat, setSelectedTierSeat] = useState(null);
    const [isTierSeatEditorOpen, setIsTierSeatEditorOpen] = useState(false);
    // memo
    const rows = useMemo(() => {
        if (!organization)
            return [];
        return [
            ...((organization === null || organization === void 0 ? void 0 : organization.tierSeats) || []).map((tierSeat) => (Object.assign({}, tierSeat))),
        ];
    }, [organization, tiers]);
    const actions = useMemo(() => {
        return [
            {
                label: 'Edit',
                onPress: (tierSeat) => {
                    setSelectedTierSeat(Object.assign({}, tierSeat));
                    setIsTierSeatEditorOpen(true);
                },
            },
        ];
    }, []);
    const unusedTiers = useMemo(() => {
        return (tiers || []).filter((tier) => {
            var _a;
            return !((_a = organization === null || organization === void 0 ? void 0 : organization.tierSeats) === null || _a === void 0 ? void 0 : _a.find((tierSeat) => tierSeat.tierId === tier.id));
        });
    }, [organization, tiers]);
    const usedTiers = useMemo(() => {
        return (tiers || []).filter((tier) => {
            var _a;
            return !!((_a = organization === null || organization === void 0 ? void 0 : organization.tierSeats) === null || _a === void 0 ? void 0 : _a.find((tierSeat) => tierSeat.tierId === tier.id));
        });
    }, [organization, tiers]);
    const isEdit = useMemo(() => {
        return !!usedTiers.find((tier) => tier.id === (selectedTierSeat === null || selectedTierSeat === void 0 ? void 0 : selectedTierSeat.tierId));
    }, [selectedTierSeat, usedTiers]);
    const availableTiers = useMemo(() => {
        return isEdit
            ? usedTiers.filter((tier) => tier.id === (selectedTierSeat === null || selectedTierSeat === void 0 ? void 0 : selectedTierSeat.tierId))
            : unusedTiers;
    }, [usedTiers, unusedTiers, selectedTierSeat, isEdit]);
    const tierInputs = useMemo(() => {
        return availableTiers.map((tier) => ({
            label: tier.name,
            value: tier.id,
        }));
    }, [availableTiers]);
    const fields = useMemo(() => {
        return createFieldHelper({
            tierId: {
                label: 'Tier',
                field: {
                    type: FieldType.Select,
                    options: tierInputs,
                },
            },
            seatLimit: {
                label: 'Seat Limit',
                field: {
                    type: FieldType.Number,
                },
            },
        });
    }, [tierInputs]);
    return (_jsxs(_Fragment, { children: [_jsx(TablePage, { title: `Organization Tiers for ${organization === null || organization === void 0 ? void 0 : organization.name}`, columns: columns, rows: rows, actions: isEditable ? actions : [], toolbarActions: isEditable && (_jsx(Button, Object.assign({ ariaLabel: 'add tier', size: 'sm', iconLeft: 'plus', isRectangle: true, isDisabled: unusedTiers.length === 0, onPress: () => {
                        var _a;
                        setSelectedTierSeat({
                            tierId: ((_a = unusedTiers[0]) === null || _a === void 0 ? void 0 : _a.id) || '',
                            tier: unusedTiers[0],
                            seatLimit: 0,
                        });
                        setIsTierSeatEditorOpen(true);
                    } }, { children: "Add Tier" }))) }), _jsx(Dialog, Object.assign({ title: isEdit ? 'Edit Tier' : 'Add Tier', isCloseButton: true, isOpen: isTierSeatEditorOpen, setIsOpen: setIsTierSeatEditorOpen }, { children: _jsx(Editor, { onSave: () => {
                        var _a;
                        if (selectedTierSeat &&
                            (selectedTierSeat.seatLimit || 0) >=
                                (selectedTierSeat.seatsUsed || 0)) {
                            organization &&
                                (updateOrganization === null || updateOrganization === void 0 ? void 0 : updateOrganization(Object.assign(Object.assign({}, organization), { tierSeats: [
                                        ...(((_a = organization === null || organization === void 0 ? void 0 : organization.tierSeats) === null || _a === void 0 ? void 0 : _a.filter((tierSeat) => tierSeat.tierId !== (selectedTierSeat === null || selectedTierSeat === void 0 ? void 0 : selectedTierSeat.tierId))) || []),
                                        ...(selectedTierSeat ? [selectedTierSeat] : []),
                                    ] })));
                        }
                        setIsTierSeatEditorOpen(false);
                    }, onCancel: () => setIsTierSeatEditorOpen(false), saveLabel: 'Save', cancelLabel: 'Cancel', isWithArrows: false, isActionsDisabled: false, value: selectedTierSeat, onChange: (res) => {
                        setSelectedTierSeat(Object.assign(Object.assign({}, res), { tier: tiers === null || tiers === void 0 ? void 0 : tiers.find((tier) => tier.id === res.tierId), seatLimit: parseInt(res.seatLimit) }));
                    }, fields: fields, isFloatingActions: true, isForm: true }) }))] }));
};
OrganizationTierTable.displayName = 'OrganizationTierTable';
export default OrganizationTierTable;
