// react
import { useCallback, useEffect, useMemo, useState } from 'react';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';

// mixpanel
import mixpanel from 'mixpanel-browser';

mixpanel.init('dda0a5648e3acf682ef6d5c57e336241', {
  track_pageview:
    process.env.NODE_ENV === 'development' ? undefined : 'full-url',
  // track_pageview: 'full-url',
});

const environment = process.env.REACT_APP_BUILD_ENVIRONMENT;

export enum MixpanelEvent {
  ArticleViewed = 'Article Viewed',
  ServiceViewed = 'Service Viewed',
  DirectoryViewed = 'Directory Viewed',
  DirectoryPhoneNumberClicked = 'Directory Phone Number Clicked',
  BookCallViewed = 'Book Call Viewed',
  CarePlanFormViewed = 'Care Plan Form Viewed',
  CarePlanComplete = 'Care Plan Complete',
  ActionPlanItemViewed = 'Action Plan Item Viewed',
  ActionPlanItemCSAT = 'Action Plan Item CSAT',
  AttendanceAllowanceEligibilitySubmitted = 'Attendance Allowance Eligibility Submitted',
  AttendanceAllowanceEligibilityResult = 'Attendance Allowance Eligibility Result',
  AttendanceAllowanceFormViewed = 'Attendance Allowance Form Viewed',
  AttendanceAllowanceFormSaved = 'Attendance Allowance Form Saved',
  AttendanceAllowanceComplete = 'Attendance Allowance Form Completed',
  AuthenticatedAndroid = 'Authenticated Android',
  AuthenticatedIOS = 'Authenticated iOS',
}

/**
 * useMixpanel is a hook that returns the current mixpanel state.
 */
export const useMixpanel = () => {
  // state
  const [isIdentified, setIsIdentified] = useState(false);

  // hooks
  const account = useSelector(getAccount);

  // memos
  const accountProperties = useMemo(() => {
    if (!account) return {};
    const accountCopy = JSON.parse(JSON.stringify(account));
    return {
      ...accountCopy,
      environment,
      actualId: accountCopy.id,
      id: accountCopy.email,
      $email: accountCopy.email,
      $name:
        (accountCopy.firstName || 'No name') +
        ' ' +
        (accountCopy.lastName || ''),
    };
  }, [account]);

  // effects
  useEffect(() => {
    if (!account || !account.email) {
      setIsIdentified(false);
      return;
    }
    mixpanel.identify(account.email);
    mixpanel.people.set(accountProperties);
    setIsIdentified(true);
  }, [account, accountProperties]);

  useEffect(() => {
    mixpanel.register(accountProperties);
  }, [accountProperties]);

  // methods
  const identify = useCallback(
    (id: string) => {
      if (!isIdentified) return;
      mixpanel.identify(id);
    },
    [isIdentified],
  );

  const alias = useCallback(
    (id: string) => {
      if (!isIdentified) return;
      mixpanel.alias(id);
    },
    [isIdentified],
  );

  const track = useCallback(
    (mixpanelEvent: MixpanelEvent, props: any) => {
      if (!environment || environment === 'local') return;
      mixpanel.track(mixpanelEvent, {
        ...props,
        ...accountProperties,
      });
    },
    [accountProperties],
  );

  return {
    isIdentified,
    identify,
    alias,
    track,
  };
};
