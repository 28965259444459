// react
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

// hooks
import { useCareProfile } from 'hooks';

// components
import { Button, OnboardingFlow, OnboardingRadio } from '@karehero/llama';

// config
import { onboardingRadioConfig } from 'config/careServices';

/**
 * OnboardingCareServices takes the user through the onboarding flow for a care concierge.
 */
const OnboardingCareServices = () => {
  // hooks
  const { careCircle } = useCareProfile();

  // methods
  const handleComplete = useCallback(() => {}, []);

  // memos
  const onboardingCareServicesConfig = useMemo(
    () => [
      {
        id: 'radio',
        Component: OnboardingRadio,
        config: onboardingRadioConfig.map((configItem) => {
          return {
            ...configItem,
            title: configItem.title?.replace(
              '{careRecipient}',
              careCircle?.careRecipientAccount?.firstName || 'your loved one',
            ),
            options: configItem.options,
            actions: [],
          };
        }),
      },
    ],
    [careCircle],
  );

  return (
    <>
      <BackButton>
        <Button
          ariaLabel='back'
          variant='secondary'
          onPress={() => window.history.back()}
          iconLeft={'chevron-left'}
        >
          Back
        </Button>
      </BackButton>
      <OnboardingFlow
        config={onboardingCareServicesConfig}
        onComplete={handleComplete}
      />
    </>
  );
};

const BackButton = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;

OnboardingCareServices.displayName = 'OnboardingCareServices';
export default OnboardingCareServices;
