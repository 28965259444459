import { path } from 'ramda';

const noop = () => {};
export const snakeToCamel = (str) =>
  str &&
  str.replace(/([_][a-z])/g, (group) => group.toUpperCase().replace('_', ''));

const getFirstPhoto = path(['photos', 0]);

const ratings = (regulator, ratingFromApi) =>
  ({
    ci: () => ({
      overallRating:
        ratingFromApi && snakeToCamel(ratingFromApi.overall_rating),
      supportWellbeing:
        ratingFromApi &&
        snakeToCamel(ratingFromApi.ratings.kq_support_wellbeing),
      leadership:
        ratingFromApi && snakeToCamel(ratingFromApi.ratings.kq_leadership),
      staffTeam:
        ratingFromApi && snakeToCamel(ratingFromApi.ratings.kq_staff_team),
      setting: ratingFromApi && snakeToCamel(ratingFromApi.ratings.kq_setting),
      careAndSupportPlanning:
        ratingFromApi &&
        snakeToCamel(ratingFromApi.ratings.kq_care_and_support_planning),
    }),
    cqc: () => ({
      overallRating:
        ratingFromApi && snakeToCamel(ratingFromApi.overall_rating),
      caring: ratingFromApi && snakeToCamel(ratingFromApi.ratings.caring),
      effective: ratingFromApi && snakeToCamel(ratingFromApi.ratings.effective),
      responsive:
        ratingFromApi && snakeToCamel(ratingFromApi.ratings.responsive),
      safe: ratingFromApi && snakeToCamel(ratingFromApi.ratings.safe),
      wellLed: ratingFromApi && snakeToCamel(ratingFromApi.ratings.well_led),
    }),
  })[regulator]();

const minimumVisitMap = {
  15: '15 minutes',
  30: '30 minutes',
  45: '45 minutes',
  60: '1 hour',
  120: '2 hours or more',
  1440: 'Live-in care',
};

export const mapSearchPayloadToProviderList = (
  providers,
  { calcDistance = noop, regulator } = {},
) =>
  providers.map((p) => ({
    careType: snakeToCamel(p.care_type),
    id: p.id,
    name: p.name,
    managerName: p.manager_name,
    dateOpened: p.date_opened,
    localAuthorityId: p.local_authority_id,
    regulatoryStatus: p.regulatory_status,
    purposeBuilt: p.purpose_built,
    regulatoryId: p.regulatory_id,
    website: p.website,
    availability: p.availability,
    pricePerWeek: p.price_per_week,
    enquiryPhone: p.enquiry_phone,
    enquiryEmail: p.enquiry_email,
    description: p.description,
    managerQuote: p.manager_quote,
    bedCount: p.bed_count,
    doubleBedCount: p.double_bed_count,
    enSuiteRoomCount: p.en_suite_room_count,
    address: {
      streetAddress1: p.address.street_address_1,
      streetAddress2: p.address.street_address_2,
      streetAddress3: p.address.street_address_3,
      streetAddress4: p.address.street_address_4,
      city: p.address.city,
      district: p.address.district,
      county: p.address.county,
      region: p.address.region,
      constituentCountry: p.address.constituent_country,
      country: p.address.country,
      postcode: p.address.postcode,
      districtType: p.address.district_type,
    },
    coverPhoto: getFirstPhoto(p),
    photos: p.photos || [],
    ratings: ratings(regulator, p.rating),
    specialisms: p.specialisms,
    location: p.location,
    distance: calcDistance(p.location),
    careServiceTypes:
      p.care_service_types && p.care_service_types.map((item) => item.name),
    covidResponse: {
      covidPpeAvailable:
        p.covid_response_details &&
        p.covid_response_details.covid_ppe_available,
      covidDistancedVisits:
        p.covid_response_details &&
        p.covid_response_details.covid_distanced_visits,
      covidControlTraining:
        p.covid_response_details &&
        p.covid_response_details.covid_control_training,
      covidNewResidentIsolation:
        p.covid_response_details &&
        p.covid_response_details.covid_new_resident_isolation,
      covidCustomFields:
        p.covid_response_details &&
        p.covid_response_details.covid_custom_fields,
    },
    minimumVisitTime: p.minimum_visit_time && {
      time: p.minimum_visit_time,
      label: minimumVisitMap[p.minimum_visit_time],
    },
    hourlyRate: p.price_per_hour,
  }));
