import api from '.';
import {
  Account,
  CredentialsKinde,
  MemberRegisterCredentials,
  Message,
} from '@karehero/models';
import { apiUrl } from 'store/api';

export const iamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    setToken: builder.mutation<Message, string>({
      query: (token) => ({
        url: '/register/token',
        method: 'POST',
        body: { token },
      }),
    }),
    checkMigrationToken: builder.query<Message, { token: string }>({
      query: ({ token }) => `/migrate/token/check?token=${token}`,
    }),
    kindeAuthenticate: builder.mutation<
      Account,
      { code: string; isMigration?: boolean }
    >({
      query: (registerRequest) => ({
        url: '/kinde/authenticate',
        method: 'POST',
        body: registerRequest,
      }),
    }),
    registerByInvite: builder.mutation<Account, { token: string }>({
      query: (credentials) => ({
        url: '/register/invite',
        method: 'POST',
        body: credentials,
      }),
    }),
    createByInvite: builder.mutation<Account, CredentialsKinde>({
      query: (registerRequest) => ({
        url: '/register/invite/create',
        method: 'POST',
        body: registerRequest,
      }),
    }),
    registerMemberByInvite: builder.mutation<Account, { token: string }>({
      query: (credentials) => ({
        url: '/register/member/invite',
        method: 'POST',
        body: credentials,
      }),
    }),
    registerMemberByInviteKey: builder.mutation<
      Account,
      { organizationID: string; inviteKey: string }
    >({
      query: (credentials) => ({
        url: '/register/member/invite-key',
        method: 'POST',
        body: credentials,
      }),
    }),
    registerMemberByDomain: builder.mutation<
      Account,
      MemberRegisterCredentials
    >({
      query: (createMember) => ({
        url: '/register/member/domain',
        method: 'POST',
        body: createMember,
      }),
    }),
    createMember: builder.mutation<Account, void>({
      query: () => ({
        url: '/register/member/create',
        method: 'POST',
      }),
    }),
    signInKinde: builder.mutation<Account, { code: string }>({
      query: (credentialsKinde) => ({
        url: '/sign-in',
        method: 'POST',
        body: credentialsKinde,
      }),
    }),
    signOut: builder.mutation<Message, void>({
      query: () => ({
        url: '/sign-out',
        method: 'POST',
      }),
    }),
    migrateMember: builder.mutation<Message, { token: string }>({
      query: (token) => ({
        url: '/migrate/member',
        method: 'POST',
        body: token,
      }),
    }),
    migrateAuthenticate: builder.mutation<Account, void>({
      query: () => ({
        url: '/migrate/authenticate',
        method: 'POST',
      }),
    }),
    updateIsOnboarded: builder.mutation<Message, void>({
      query: () => ({
        url: '/onboarded',
        method: 'POST',
      }),
    }),
    updateIsOnboardedTooltip: builder.mutation<void, string>({
      query: (key: string) => ({
        url: `/onboarded/tooltip/${key}`,
        method: 'POST',
      }),
    }),
    updateInterests: builder.mutation<Account, { interests: any[] }>({
      query: (request) => ({
        url: '/account/interests',
        method: 'PUT',
        body: request,
      }),
    }),
    renewSession: builder.query<void, void>({
      query: () => `${apiUrl}/renew-session`,
    }),
    version: builder.query<Message, void>({
      query: () => `/version`,
    }),
    updateCareCircleNames: builder.mutation<
      { caregiverFirstName: string; careRecipientFirstName: string },
      { caregiverFirstName: string; careRecipientFirstName: string }
    >({
      query: (res) => ({
        url: `/care-circle/names`,
        method: 'PUT',
        body: res,
      }),
      invalidatesTags: ['CareCircles'],
    }),
  }),
});

export const {
  useRegisterByInviteMutation,
  useCreateByInviteMutation,
  useRegisterMemberByInviteMutation,
  useRegisterMemberByInviteKeyMutation,
  useRegisterMemberByDomainMutation,
  useCreateMemberMutation,
  useKindeAuthenticateMutation,
  useUpdateInterestsMutation,
  useSignInKindeMutation,
  useSignOutMutation,
  useMigrateMemberMutation,
  useMigrateAuthenticateMutation,
  useUpdateIsOnboardedMutation,
  useUpdateIsOnboardedTooltipMutation,
  useLazyRenewSessionQuery,
  useLazyVersionQuery,
  useSetTokenMutation,
  useUpdateCareCircleNamesMutation,
  useLazyCheckMigrationTokenQuery,
} = iamApi;

export default iamApi;
