import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { css } from 'styled-components';
// icons
import { Icon } from "..";
/**
 * Chip is a component that displays a chip.
 */
export const Chip = ({ label, isSelected = false, onClick, icon, isDisabled = false, }) => {
    return (_jsxs(StyledChip, Object.assign({ onClick: onClick, "$isButton": !!onClick, "$isSelected": isSelected, "$isDisabled": isDisabled }, { children: [icon && _jsx(Icon, { icon: icon }), " ", _jsx(Label, { children: label })] })));
};
const StyledChip = styled.div `
  display: inline-flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  background: ${(props) => props.theme.color.neutral[40]};
  border-radius: 99999px;
  padding: ${(props) => props.theme.spacing[8]}
    ${(props) => props.theme.spacing[16]};
  color: ${(props) => props.theme.color.neutral[90]};
  user-select: none;
  white-space: nowrap;
  transition-duration: 200ms;

  ${(props) => props.$isButton &&
    !props.$isSelected &&
    css `
    cursor: pointer;
    &:hover {
      background: ${props.theme.color.neutral[90]};
      color: ${props.theme.color.neutral[10]};
  `}

  ${(props) => props.$isSelected &&
    css `
      cursor: default;
      pointer-events: none;
      background: ${props.theme.color.neutral[90]};
      color: ${props.theme.color.neutral[10]};
    `}

  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[30]};
      color: ${props.theme.color.neutral[50]};
      cursor: default;
    `}
`;
const Label = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
`;
Chip.displayName = 'Chip';
export default Chip;
