import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
// components
import { Logo } from "../../atoms";
import { Container } from "../../atoms/Container/Container";
import { CarouselIndicator } from "../../atoms/CarouselIndicator/CarouselIndicator";
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import { theme } from "../../../theme";
import { css } from 'styled-components';
// assets
// @transform-path ../../../../../../src/assets/onboarding/filing-cabinet.svg
import filingCabinet from "../../../../../../src/assets/onboarding/filing-cabinet.svg";
// @transform-path ../../../../../../src/assets/onboarding/relaxed.svg
import relaxed from "../../../../../../src/assets/onboarding/relaxed.svg";
// @transform-path ../../../../../../src/assets/onboarding/pocket.svg
import pocket from "../../../../../../src/assets/onboarding/pocket.svg";
const assetOrder = [filingCabinet, pocket, relaxed];
/**
 * OnboardingCarousel renders the carousel onboarding views.
 */
export const OnboardingCarousel = toGenericOnboarding(({ config, currentIndex = 0, setIsNextDisabled, }) => {
    var _a, _b, _c;
    // hooks
    const [width] = useWindowSize();
    // memo
    const isMobile = useMemo(() => width < theme.breakpoint.mdValue, [width]);
    // effects
    useEffect(() => {
        setIsNextDisabled(false);
    }, [setIsNextDisabled]);
    return (_jsx(StyledContainer, { children: _jsxs(Wrapper, { children: [_jsx(LogoContainer, { children: _jsx(Logo, { size: 'md' }) }), _jsxs(MainWrapper, { children: [_jsx(ImageWrapper, { children: _jsx("img", { alt: 'header', src: assetOrder[currentIndex] }) }), _jsxs(ContentWrapper, { children: [!isMobile && _jsx(Title, { children: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.title }), _jsxs("div", { children: [_jsx(SubTitle, { children: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.subTitle }), _jsx(Text, { children: (_c = config[currentIndex]) === null || _c === void 0 ? void 0 : _c.text })] })] })] }), _jsx(IndicatorWrapper, { children: _jsx(CarouselIndicator, { total: config.length, index: currentIndex }) })] }) }));
});
const LogoContainer = styled.div `
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${(props) => props.theme.spacing[24]} 0;
`;
const StyledContainer = styled(Container) `
  overflow-x: hidden;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-top: ${(props) => props.theme.spacing[24]};
  gap: ${(props) => props.theme.spacing[24]};
  height: 100%;

  ${(props) => props.theme.breakpoint.md(css `
      justify-content: flex-start;
      gap: ${props.theme.spacing[24]};
    `)};
`;
const MainWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[24]};
  flex: 1;

  ${(props) => props.theme.breakpoint.md(css `
      flex-direction: row;
      align-items: center;
      gap: ${props.theme.spacing[64]};
    `)}
`;
const IndicatorWrapper = styled.div `
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: ${(props) => props.theme.spacing[32]};
`;
const ImageWrapper = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  height: 200px;
  max-height: 200px;
  overflow: visible;
  flex: 1;
  ${(props) => props.theme.breakpoint.md(css `
      justify-content: flex-end;
      height: 365px;
      max-height: 365px;
    `)}

  & > img {
    border-radius: 8px;
    width: 100%;
    max-width: 465px;
    object-fit: contain;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    ${(props) => props.theme.breakpoint.md(css `
        width: auto;
        object-fit: contain;
      `)}
  }
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[36]};
  flex: 1;

  ${(props) => props.theme.breakpoint.md(css `
      align-items: flex-start;
    `)}
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.primary[80]};
  min-height: 60px;
  text-align: center;

  ${(props) => props.theme.breakpoint.md(css `
      min-height: none;
      font: ${props.theme.typography.heading['6xl']};
      text-align: left;
    `)}
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.primary[80]};
  margin-bottom: ${(props) => props.theme.spacing[8]};
  text-align: center;

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.heading['3xl']};
      text-align: left;
    `)}
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: center;

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.body.xl.regular};
      text-align: left;
    `)}
`;
OnboardingCarousel.displayName = 'OnboardingCarousel';
export default toGenericOnboarding(OnboardingCarousel);
