import PropTypes from 'prop-types';

export const careTypeType = PropTypes.oneOf(['careHome', 'homeCare']);

export const addressType = PropTypes.shape({
  streetAddress1: PropTypes.string,
  streetAddress2: PropTypes.string,
  streetAddress3: PropTypes.string,
  streetAddress4: PropTypes.string,
  city: PropTypes.string,
  district: PropTypes.string,
  county: PropTypes.string,
  region: PropTypes.string,
  constituentCountry: PropTypes.string,
  country: PropTypes.string,
  postcode: PropTypes.string,
  districtType: PropTypes.string,
});

export const locationType = PropTypes.shape({
  lat: PropTypes.number,
  lng: PropTypes.number,
});

export const ratingsValuesTypeCi = PropTypes.oneOf([
  'excellent',
  'veryGood',
  'good',
  'adequate',
  'weak',
  'unsatisfactory',
  'notPublished',
]);

export const ratingsTypeCi = PropTypes.shape({
  overallRating: ratingsValuesTypeCi,
  supportWellbeing: ratingsValuesTypeCi,
  leadership: ratingsValuesTypeCi,
  staffTeam: ratingsValuesTypeCi,
  setting: ratingsValuesTypeCi,
  careAndSupportPlanning: ratingsValuesTypeCi,
});

export const ratingsValuesTypeCqc = PropTypes.oneOf([
  'outstanding',
  'good',
  'requiresImprovement',
  'inadequate',
]);

export const ratingsTypeCqc = PropTypes.shape({
  overallRating: ratingsValuesTypeCqc,
  caring: ratingsValuesTypeCqc,
  effective: ratingsValuesTypeCqc,
  responsive: ratingsValuesTypeCqc,
  safe: ratingsValuesTypeCqc,
  wellLed: ratingsValuesTypeCqc,
});

export const ratingsType = PropTypes.oneOfType([ratingsTypeCi, ratingsTypeCqc]);

export const imageType = PropTypes.shape({
  tiny: PropTypes.string,
  small: PropTypes.string,
  medium: PropTypes.string,
  large: PropTypes.string,
});

export const providerType = PropTypes.shape({
  id: PropTypes.string,
  regulatoryId: PropTypes.string.isRequired,
  careType: careTypeType,
  dateOpened: PropTypes.string,
  enquiryEmail: PropTypes.string,
  localAuthorityId: PropTypes.number,
  managerName: PropTypes.string,
  name: PropTypes.string.isRequired,
  purposeBuilt: PropTypes.bool,
  regulatoryStatus: PropTypes.string,
  website: PropTypes.string,
  availability: PropTypes.bool,
  pricePerWeek: PropTypes.number,
  enquiryPhone: PropTypes.string,
  description: PropTypes.string,
  managerQuote: PropTypes.string,
  bedCount: PropTypes.number,
  doubleBedCount: PropTypes.number,
  enSuiteRoomCount: PropTypes.number,
  address: addressType,
  careServiceTypes: PropTypes.arrayOf(PropTypes.string),
  coverPhoto: imageType,
  photos: PropTypes.arrayOf(imageType),
  ratings: ratingsType,
  specialisms: PropTypes.arrayOf(PropTypes.string),
  location: locationType,
  hourlyRate: PropTypes.number,
  minimumVisitTime: PropTypes.shape({
    time: PropTypes.number,
    label: PropTypes.string,
  }),
});

export const iconType = PropTypes.shape({
  prefix: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.array.isRequired,
});

export const regulatorType = PropTypes.oneOf(['ci', 'cqc']);
